

import {HttpClient as Http, HttpParams} from '@angular/common/http'
import {HttpHeaders as Headers} from '@angular/common/http'
import { Injectable } from "@angular/core";
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalService } from './global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import {from, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as moment from 'moment';

export interface Order {
    // Properties
  }
  
  interface ResponseOrders {
    results: Order[];
  }

@Injectable()

export class ApiService {
    
    // public ServerUrl = 'https://silent-cairo-0dmu3mzxhu4l.vapor-farm-f1.com/api/';
    // public ServerUrl = 'https://resilient-reef-nnuhazjtxguh.vapor-farm-g1.com/api/';
     public ServerUrl = 'https://backoffice-api.bitandpay.com/api/';
    // public ServerUrl = 'http://127.0.0.1:8000/api/';
    
    constructor(public activatedRoute: ActivatedRoute, public global: GlobalService, private http: Http) { };

    // downloadPkpass(ss): Observable<any> {
    //     return from(
    //       fetch(
    //         'http://ec2-13-41-194-71.eu-west-2.compute.amazonaws.com/api/generate_pkpass', // the url you are trying to access
    //         {
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //           method: 'GET', // GET, POST, PUT, DELETE
    //           mode: 'no-cors',
    //         }
    //       ));
    //   }
     params: HttpParams 
     
    public downloadPkpass(card: any) {
        // let body = new FormData();
        // body.append('obj', 'sdfdfs');

        let ii = {
            card:JSON.stringify(card)
        }
      let expp = card.card_data.expiryDate;
      try{
        expp = moment(card.card_data.expiryDate).format('MM/YY');
      }catch(err){

      }
     
        // this.params.append('dasdas','asdsada')
        return this.http.get(`https://d3ma3mowzikgkq.cloudfront.net/api/generate_pkpass?card=${card.card_data.cardNumber}&cardmask=${card.card_data.maskedCardNumber}&exp=${expp}&cvv=${card.card_data.cvv}&holder=${card.card_data.embossName}`,{responseType:'blob'}).toPromise();
      }
    
    //   public convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onerror = reject;
    //     reader.onload = () => {
    //       resolve(reader.result);
    //     };
    //     reader.readAsDataURL(blob);
    //   });
       blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }
      
    async sendRequest_widget(url: string, obj: Object): Promise<Array<any>> {

        return new Promise<Array<any>>(async (resolve, reject) => {
            let body = new FormData();
            body.append('obj', JSON.stringify(obj));
            try {
                let answer = await this.http.post(this.ServerUrl + '' + url, body).map((res:any) => res).do((data) => { }).toPromise();
                resolve(answer);
            } catch (err) {
                reject(err);
            } finally {
                // this.global.niceLoader = false
            }
        });
    }

    async sendRequest(url: string, obj: any): Promise<Array<any>> {
        return new Promise<Array<any>>(async (resolve, reject) => {
          
            let body = new FormData();
            obj.user_id = ''
            obj.token = ''
            if (url == 'gateway_merchant_tnx_list') {
                await Auth.currentCredentials()
                    .then(credentials => {
                        obj.credentials = Auth.essentialCredentials(credentials)
                    })
            }
            body.append('obj', JSON.stringify(obj));
            const token = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            // const headers = new Headers();
            const headers = new Headers().append('Content-Type', 'application/json; charset=utf-8').append('Authorization',token);
            // headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization',token);
            // headers.append('Authorization', token);

            let options = {
                headers:  {
                    'Authorization': token,
                }
            }
            try {
                let answer = await this.http.post(this.ServerUrl + '' + url, body,options).map((res:any) => res).do((data) => { }).toPromise();
                if (answer.permission == 'Permission denied') {
                    // this.global.logout();
                    // return
                }
                resolve(answer);
            } catch (err) {
                // this.global.dismissLoading()
                reject(err);
            } finally {
                if (url != 'cryptopaynplay_create_invoice') {
                    // this.global.dismissLoading()
                }
                this.global.niceLoader = false
            }
        });
    }

   

}
