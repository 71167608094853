/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "eu-central-1",
//     "aws_appsync_graphqlEndpoint": "https://iymbwqw4rvblbocifglrfsruuy.appsync-api.eu-central-1.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-central-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_cognito_identity_pool_id": "eu-central-1:5a3c77d4-7717-421a-8c15-5a37d20c013d",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_VcsQeSUeN",
//     "aws_user_pools_web_client_id": "1la27i8dkhle1tsgrd6lafqfbh",
//     "oauth": {},
// };


// const awsmobile = {
//     "aws_project_region": "eu-central-1",
//     "aws_appsync_graphqlEndpoint": "https://iymbwqw4rvblbocifglrfsruuy.appsync-api.eu-central-1.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-central-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_cognito_identity_pool_id": "eu-central-1:dd9e3074-44b1-41c2-a317-ccb7b2cf0558",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_8aBQu4rex",
//     "aws_user_pools_web_client_id": "gfc9d7qhlr8tovib12gqhbrf2",
//     "oauth": {},
// };

// const awsmobile = {
//     "aws_project_region": "eu-central-1",
//     "aws_appsync_graphqlEndpoint": "https://iymbwqw4rvblbocifglrfsruuy.appsync-api.eu-central-1.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-central-1",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
//     "aws_cognito_identity_pool_id": "eu-central-1:38fbb94a-9560-44fc-8378-c8f8145e5379",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_B2crRT2IB",
//     "aws_user_pools_web_client_id": "5er8rbau7kcljeeppks30bphfj",
//     "oauth": {}
// };

//export default awsmobile;
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0qzvqy6a30.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_VcsQeSUeN",
    "aws_user_pools_web_client_id": "6tcjd93ajesfgtj38ttqcmoh5i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ]
};


export default awsmobile;

