import { Injectable } from "@angular/core";
import 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable()

export class AuthService {
  user:any
  password: string = '111111'
  constructor() { };
  async signUp(username, email) {
    try {
      // await Auth.signUp({
      //   username: username,
      //   password: this.password,
      //   attributes: {
      //     email: email
      //   }
      // });
      return this.signIn(username)
    } catch (error) {
      return this.signIn(username)
    }
  }

  async signIn(username) {
    try {
    this.user = await Auth.signIn(username, this.password);
      if (this.user.challengeName === 'SMS_MFA') {
        return { status: 'success'}
      }
    } catch (error) {
      return { status: 'fail', message: error.message }
    }
  }

  async confirmSignIn(code) {
    try {
      const loggedUser = await Auth.confirmSignIn(
        this.user,
        code,
        'SMS_MFA'
      );
      return { status: 'success', user: this.user }
    } catch (error) {
      return { status: 'fail', message: error.message }
    }
  }

}