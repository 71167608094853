import { Injectable,NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import  { Auth } from 'aws-amplify';
import { LoadingController, ModalController,NavController,ToastController } from '@ionic/angular';
// import { Card } from '../auth/Models/CreditCard.model';
import { validateIBAN } from 'ngx-iban-validator';
import { FormControl, Validators } from '@angular/forms';
// import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  activeCrypto = 'BTC'
  user_type = null
  enterType = 'Register'
  face_id_enable = false

    complianceForm: any = {}
    CountryISO:any = []
  
    firstTime = true
    ngOnInit() {
        this.CountryISO = this.countriesObjectList;
    //   this.gateway_admin_get_merchant()
      // if(localStorage.getItem('complianceFileds')){
      //   this.complianceFileds = JSON.parse(localStorage.getItem('complianceFileds'))
      // }
    }
  
  iban:any

  nextFirstStepDone = false
  nextFirstStep(){
    this.firstTime = false
    // const ibanIsInvalid = validateIBAN({
    //   value: 'AL35202111090000000001234567',
    // }).ibanInvalid;

    // console.log(ibanIsInvalid)
    this.nextFirstStepDone = true
  
    this.complianceFileds[0].fields.forEach(element => {
            console.log(element.label)
            if(element.required && (element.value == null || element.value.length < 2)){
                this.nextFirstStepDone = false
              element.error = true
            }else{
                  element.error = false
                }
        });

        return this.nextFirstStepDone;
  }
  nextFinalStepDone = false
  nextFinalStep(){
    if(this.user.user_type == 'Personal'){
     return this.nextFinalStepPersonal()
    }

    this.firstTime = false
    // const ibanIsInvalid = validateIBAN({
    //   value: 'AL35202111090000000001234567',
    // }).ibanInvalid;

    // console.log(ibanIsInvalid)
    this.nextFinalStepDone = true
  
    this.complianceFileds[2].fields.forEach(element => {
        if(element.required && (element.value == null || element.value.length < 2)){
            this.nextFinalStepDone = false
            element.error = true
          }else{
            
            if(element.label == 'IBAN'){
              const ibanIsInvalid = validateIBAN({
                value: element.value,
              }).ibanInvalid;

              element.error = ibanIsInvalid
              if(!ibanIsInvalid){
                this.iban = element.value
              }else{
                this.nextFinalStepDone = false
              }
            }else{
              if(element.label == 'Confirm IBAN'){
                if(element.value != this.iban){
                  this.nextFinalStepDone = false
                  element.error = true
                }else{
                  element.error = false
                }
              }else{
                element.error = false
              }
            
            }

          }
        });

        return this.nextFinalStepDone;
  }

  nextFinalStepPersonal(){
    this.firstTime = false
    // const ibanIsInvalid = validateIBAN({
    //   value: 'AL35202111090000000001234567',
    // }).ibanInvalid;

    // console.log(ibanIsInvalid)
    this.nextFinalStepDone = true
  
    this.complianceFileds[1].fields.forEach(element => {
      if(element.label == 'Email address'){
          const control = new FormControl(element.value, Validators.email);
          console.log('eee ',control.errors)
          try{
            if(control.errors.email){
              this.nextFinalStepDone = false
              element.error = true
            }
            if(control.errors == null && (element.value == null || element.value.length < 2)){
              this.nextFinalStepDone = false
              element.error = true
            }
          }catch(err){
              if((element.value == null || element.value.length < 2)){
                  this.nextFinalStepDone = false
                  element.error = true
                }
          }
        }else{
          if(element.required && (element.value == null || element.value.length < 2)){
              this.nextFinalStepDone = false
            element.error = true
          }else{
                element.error = false
              }
        }

        
      });


      let i = 0
      this.complianceFileds[0].fields.forEach(element => {
        
if(i != 5 && i != 6 && i != 0){
  if(element.required && (element.value == null || element.value.length < 2)){
    this.nextFinalStepDone = false
  element.error = true
}else{
      element.error = false
    }
}

        


            i++
    });


    console.log(this.nextFinalStepDone)

        return this.nextFinalStepDone;
  }


  
  nextSecondStepDone = false
  nextSecondStep(){
    this.firstTime = false
    // const ibanIsInvalid = validateIBAN({
    //   value: 'AL35202111090000000001234567',
    // }).ibanInvalid;

    // console.log(ibanIsInvalid)
    this.nextSecondStepDone = true
  
    this.complianceFileds[1].fields.forEach(element => {
        if(element.label == 'Email address'){
            const control = new FormControl(element.value, Validators.email);
            console.log('eee ',control.errors)
            try{
              if(control.errors.email){
                this.nextSecondStepDone = false
                element.error = true
              }
              if(control.errors == null && (element.value == null || element.value.length < 2)){
                this.nextSecondStepDone = false
                element.error = true
              }
            }catch(err){
                if((element.value == null || element.value.length < 2)){
                    this.nextSecondStepDone = false
                    element.error = true
                  }
            }
          }else{
            if(element.required && (element.value == null || element.value.length < 2)){
                this.nextSecondStepDone = false
              element.error = true
            }else{
                  element.error = false
                }
          }

          
        });

        return this.nextSecondStepDone;
  }


  
    save(){
      this.firstTime = false
      // const ibanIsInvalid = validateIBAN({
      //   value: 'AL35202111090000000001234567',
      // }).ibanInvalid;
  
      // console.log(ibanIsInvalid)
      let complianceIsDone = true
      this.complianceFileds.forEach(element => {
        element.fields.forEach(element => {
              console.log(element.label)
              if(element.required && (element.value == null || element.value.length < 2)){
                complianceIsDone = false
                element.error = true
              }else{
                
                if(element.label == 'IBAN'){
                  const ibanIsInvalid = validateIBAN({
                    value: element.value,
                  }).ibanInvalid;
  
                  element.error = ibanIsInvalid
                  if(!ibanIsInvalid){
                    this.iban = element.value
                  }else{
                    complianceIsDone = false
                  }
                }else{
                  if(element.label == 'Confirm IBAN'){
                    if(element.value != this.iban){
                      complianceIsDone = false
                      element.error = true
                    }else{
                      element.error = false
                    }
                  }else{
                    element.error = false
                  }
                
                }
  
              }
              if(element.label == 'Email address'){
                const control = new FormControl(element.value, Validators.email);
                console.log('eee ',control.errors)
                try{
                  if(control.errors.email){
                    complianceIsDone = false
                    element.error = true
                  }
                }catch(err){
                  //  complianceIsDone = false
                }
               
                
              }
          });
      });
  
      let obj:any = {
        complianceData:JSON.stringify(this.complianceFileds),
        complianceIsDone:complianceIsDone
      }


    //   this.api.sendRequest('bitandpay_update_user_compliance_data',obj).then((data:any)=>{
    //     if(data == '1'){
    //       this.gateway_admin_get_merchant()
    //       // this.dismissModel()
    //     }
    //   })
      // localStorage.setItem('complianceFileds',JSON.stringify(this.complianceFileds))
      console.log(this.complianceFileds)
      console.log('saved')
    }
  
  
  
    gateway_admin_get_merchant() {
    //   let obj: any = {
    //     user_id: this.user.id,
    //     token: localStorage.getItem('crypto_admin_user_token')
    //   }
    //   this.api.sendRequest('gateway_admin_get_merchant', obj)
    //     .then((data: any) => {
    //       this.niceLoader = false
    //       if (data == '0') {
    //       } else {
    //         try{
    //           if(data.white_list != null){
    //             // this.complianceFileds = JSON.parse(data.white_list)
    //             this.complianceFileds = data.white_list
    //           }
    //         }catch(err){
  
    //         }
    //         try{
    //           if(data.wallet == '1'){
    //             this.complianceDone = true
    //             this.dismissModel()
    //           }
    //         }catch(err){
              
    //         }
    //       }
    //     }).catch(err => {
    //       console.log(err)
    //     })
    }


creditCardssetting: any = [
    
//   {
//     id: 3,
//     icon: "/assets/img/master-card.svg",
//     firstName: "John",
//     lastName: "Smith",
//     ending: "Awaiting Approval",
//     amount: "80,000.00",
//     currency: "€",
//     expiryDate: "01/01",
//     cvv: 234,
//     cardType:"VISA",
//     cardNumber:"1234 1234 1111 6789",
//     pending:true,


//   }
// ,  
  
//   {
//   id: 1,
//   icon: "/assets/img/visa.svg",
//   firstName: "John",
//   lastName: "Smith",
//   ending: "5678",
//   amount: "132,000.00",
//   currency: "€",
//   expiryDate: "01/01",
//   cvv: 234,
//   cardType:"VISA",
//   cardNumber:"1234 1234 5678 7856",
//   pending:false,
// }, {
//   id: 2,
//   icon: "/assets/img/american-express.svg",
//   firstName: "John",
//   lastName: "Smith",
//   ending: "5678",
//   amount: "892.02",
//   currency: "€",
//   expiryDate: "01/01",
//   cvv: 234,
//   cardType:"VISA",
//   cardNumber:"1234 1234 1234 4321",
//   pending:false,
// }, 
{
  id: 3,
  icon: "/assets/img/master-card.svg",
    firstName: "EDEN",
    lastName: "GOETA",
    ending: "9304",
    amount: "100.00",
    currency: "€",
    expiryDate: "09/25",
    cvv: 323,
    cardType:"MASTERCARD",
    cardNumber:"5299 8750 5395 9304",
  pending:false,


}


]

   creditCards: any = [
    
//     {
//       id: 3,
//       icon: "/assets/img/master-card.svg",
//       firstName: "John",
//       lastName: "Smith",
//       ending: "Awaiting Approval",
//       amount: "80,000.00",
//       currency: "€",
//       expiryDate: "01/01",
//       cvv: 234,
//       cardType:"VISA",
//       cardNumber:"1234 1234 1111 6789",
//       pending:true,
  
  
//     }
// ,  
    
//     {
//     id: 1,
//     icon: "/assets/img/visa.svg",
//     firstName: "John",
//     lastName: "Smith",
//     ending: "5678",
//     amount: "132,000.00",
//     currency: "€",
//     expiryDate: "01/01",
//     cvv: 234,
//     cardType:"VISA",
//     cardNumber:"1234 1234 5678 7856",
//     pending:false,
//   }, {
//     id: 2,
//     icon: "/assets/img/american-express.svg",
//     firstName: "John",
//     lastName: "Smith",
//     ending: "5678",
//     amount: "892.02",
//     currency: "€",
//     expiryDate: "01/01",
//     cvv: 234,
//     cardType:"VISA",
//     cardNumber:"1234 1234 1234 4321",
//     pending:false,
//   }, 
  // {
  //   id: 3,
  //   icon: "/assets/img/master-card.svg",
  //   firstName: "EDEN",
  //   lastName: "GOETA",
  //   ending: "9304",
  //   amount: "100.00",
  //   currency: "€",
  //   expiryDate: "09/25",
  //   cvv: 323,
  //   cardType:"MASTERCARD",
  //   cardNumber:"5299 8750 5395 9304",
  //   pending:false,


  // }
  

]

creditCardsPersonal:any = []
    doneLoadingInvoice = false

    copy(position, message) {
        var input = document.createElement('textarea');
        input.innerHTML = this.invoiceObject.btcAddress;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        this.presentToast(position, message)
        return result;
    }


  invoiceObject:any = {
    orderAmount:null,
    orderAmountFiat:null,
    cryptoCode:null,
    paymentMethodId:null,
    btcAddress:null,
    rate:null,
    btcPaid:null,
    btcDue:null,
    status:null,
    merchantRefLink:null,
    invoiceBitcoinUrlQR:'null',
    invoiceBitcoinUrl:null
  } 

tabsBackgroudColor = false
  isMonitorApp = false
  needKyc = false
  finishedKyc = false
  success = false
  kycObject = {}
  selectsDropdownData:any = []
  showModal = false
  menuItems = []
  dashboardIframeURL = null
  direction = 'left'
  terminalLive = false
  hideHeader = false
  fullPhoneNumber = null
  loginType = 'Admin'
  cognito_user: CognitoUserInterface | undefined;
  authState: AuthState;
  MONITOR_ON = false
  pageVar = 'sign-in'
  user: any = {}

  MONITOR_CONNECTED = false
  niceLoader = false
  auth_variable_pages = 'login';
  monitorCode = 'aaa'
  constructor(
    private navCtrl: NavController,
    private router: Router,
    private location: Location,
    public loadingController: LoadingController,
    public ngzone:NgZone,
    public toastController: ToastController,
    public modalCtrl: ModalController,
    // public api:ApiService
  ) { }

  async presentToast(position, message) {
    const toast = await this.toastController.create({
       position: position,
      message: message,
      duration: 2000
    });
    toast.present();
  }

  goToPage(page, arg = {}) {
    this.ngzone.run(() => {
      this.navCtrl.navigateForward(page, arg);
      // this.router.navigate([page, arg])
    })
  }


  goToPage2(page, arg = {}) {
    this.ngzone.run(() => {
      // this.navCtrl.navigateForward(page, arg);
      this.router.navigate([page, arg])
    })
  }
  dismissModel(){
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  goBack() {
    this.navCtrl.back();
    // this.location.back();
  }
   async dismissLoading() {
    try{
       await this.loadingController.dismiss();
    }catch(err){
      console.log(err)
    }
  }
  loadingMessage = ''
  updatePercent = ''
  async presentLoading(update = null) {
    let obj:any = {
        spinner: 'crescent',
        mode:'ios',
        // duration: 3000,
        cssClass: 'my-loading-class',
        // translucent:true
      }
      if(update == 1){
          obj.message = this.loadingMessage + ' ' +this.updatePercent
      }else{
          obj.duration = 3000
      }
    const loading = await this.loadingController.create(obj);
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }
  returnSession = 0
  async presentLoadingMessage(message) {
    let obj:any = {
        spinner: 'crescent',
        mode:'ios',
        message:message,
        duration: 6000,
        cssClass: 'my-loading-class',
        // translucent:true
      }
    const loading = await this.loadingController.create(obj);
    await loading.present();

    // const { role, data } = await loading.onDidDismiss();
    // console.log('Loading dismissed!');
  }



      async logout() {
        // this.firstTime = true
        this.enterType = 'Register'
        this.user_type = null
        try{
          if(localStorage.getItem('face_id_enable')){
            localStorage.removeItem('face_id_enable')
          }
        
        }catch(err){

        }
       
        this.returnSession = 0
        this.clearComplianceFields()
        
            await Auth.signOut()
            // this.loginType = null
            this.pageVar = 'sign-in'
            this.MONITOR_ON = false
            
            this.user = {};
            localStorage.removeItem('crypto_admin_user');
            localStorage.removeItem('crypto_admin_user_token');
            localStorage.removeItem('monitorsArray');
            localStorage.removeItem('monitor');
        
            
            // this.auth_variable_pages = 'login'
            this.goToPage('/login')
            // this.router.dispose()
          }


          clearComplianceFields(){
            this.complianceDone = false
            this.complianceFileds = [
              {
                category: 'Business details',
                subtitle:'The name you provide must exactly match the name associated with your tax ID.',
                fields: [
                  {
                    label: 'Legal business name',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Country',
                    desc: 'Registered business Country',
                    type: 'select',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Address line 1',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Address line 2',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: false,
                    error: false
                  },
                  {
                    label: 'Postal code',
                    desc: '',
                    type: 'number',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Business website',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: false,
                    error: false
                  },
                  {
                    label: 'Product description',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: false,
                    error: false
                  }
                ]
              },
              {
                category: 'Business owners',
                subtitle:'Please add any individual who owns 25% or more of the company.',
                fields: [
                  {
                    label: 'First name',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Last name',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Email address',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  }
                ]
              },
              {
                category: 'Bank details',
                subtitle:'',
                fields: [
                  {
                    label: 'Currency',
                    desc: '',
                    type: 'select',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Country of bank account',
                    desc: '',
                    type: 'select',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'IBAN',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  },
                  {
                    label: 'Confirm IBAN',
                    desc: '',
                    type: 'text',
                    value: null,
                    required: true,
                    error: false
                  }
                ]
              }
            ]
          }

          complianceDone = false
  
          complianceFileds:any = [
            {
              category: 'Business details',
              subtitle:'The name you provide must exactly match the name associated with your tax ID.',
              fields: [
                {
                  label: 'Legal business name',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Country',
                  desc: 'Registered business Country',
                  type: 'select',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Address line 1',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Address line 2',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: false,
                  error: false
                },
                {
                  label: 'Postal code',
                  desc: '',
                  type: 'number',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Business website',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: false,
                  error: false
                },
                {
                  label: 'Product description',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: false,
                  error: false
                }
              ]
            },
            {
              category: 'Business owners',
              subtitle:'Please add any individual who owns 25% or more of the company.',
              fields: [
                {
                  label: 'First name',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Last name',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Email address',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                }
              ]
            },
            {
              category: 'Bank details',
              subtitle:'',
              fields: [
                {
                  label: 'Currency',
                  desc: '',
                  type: 'select',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Country of bank account',
                  desc: '',
                  type: 'select',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'IBAN',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                },
                {
                  label: 'Confirm IBAN',
                  desc: '',
                  type: 'text',
                  value: null,
                  required: true,
                  error: false
                }
              ]
            }
          ]
          userCompliance:boolean = false

          countriesObjectList = [
            // {
            //     "name": "Afghanistan (‫افغانستان‬‎)",
            //     "iso2": "af",
            //     "dialCode": "93",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-af",
            //     "flagClass": "iti__af",
            //     "placeHolder": "+93 23 456 7890"
            // },
            // {
            //     "name": "Albania (Shqipëri)",
            //     "iso2": "al",
            //     "dialCode": "355",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-al",
            //     "flagClass": "iti__al",
            //     "placeHolder": "+355 22 345 678"
            // },
            // {
            //     "name": "Algeria (‫الجزائر‬‎)",
            //     "iso2": "dz",
            //     "dialCode": "213",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-dz",
            //     "flagClass": "iti__dz",
            //     "placeHolder": "+213 12 34 56 78"
            // },
            // {
            //     "name": "American Samoa",
            //     "iso2": "as",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "684"
            //     ],
            //     "htmlId": "iti-0__item-as",
            //     "flagClass": "iti__as",
            //     "placeHolder": "+1 684-622-1234"
            // },
            // {
            //     "name": "Andorra",
            //     "iso2": "ad",
            //     "dialCode": "376",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ad",
            //     "flagClass": "iti__ad",
            //     "placeHolder": "+376 712 345"
            // },
            // {
            //     "name": "Angola",
            //     "iso2": "ao",
            //     "dialCode": "244",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ao",
            //     "flagClass": "iti__ao",
            //     "placeHolder": "+244 222 123 456"
            // },
            // {
            //     "name": "Anguilla",
            //     "iso2": "ai",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "264"
            //     ],
            //     "htmlId": "iti-0__item-ai",
            //     "flagClass": "iti__ai",
            //     "placeHolder": "+1 264-461-2345"
            // },
            // {
            //     "name": "Antigua and Barbuda",
            //     "iso2": "ag",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "268"
            //     ],
            //     "htmlId": "iti-0__item-ag",
            //     "flagClass": "iti__ag",
            //     "placeHolder": "+1 268-460-1234"
            // },
            {
                "name": "Argentina",
                "iso2": "ar",
                "dialCode": "54",
                "priority": 0,
                "htmlId": "iti-0__item-ar",
                "flagClass": "iti__ar",
                "placeHolder": "+54 11 2345-6789"
            },
            {
                "name": "Armenia (Հայաստան)",
                "iso2": "am",
                "dialCode": "374",
                "priority": 0,
                "htmlId": "iti-0__item-am",
                "flagClass": "iti__am",
                "placeHolder": "+374 10 123456"
            },
            // {
            //     "name": "Aruba",
            //     "iso2": "aw",
            //     "dialCode": "297",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-aw",
            //     "flagClass": "iti__aw",
            //     "placeHolder": "+297 521 2345"
            // },
            {
                "name": "Australia",
                "iso2": "au",
                "dialCode": "61",
                "priority": 0,
                "htmlId": "iti-0__item-au",
                "flagClass": "iti__au",
                "placeHolder": "+61 2 1234 5678"
            },
            {
                "name": "Austria (Österreich)",
                "iso2": "at",
                "dialCode": "43",
                "priority": 0,
                "htmlId": "iti-0__item-at",
                "flagClass": "iti__at",
                "placeHolder": "+43 1 234567890"
            },
            // {
            //     "name": "Azerbaijan (Azərbaycan)",
            //     "iso2": "az",
            //     "dialCode": "994",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-az",
            //     "flagClass": "iti__az",
            //     "placeHolder": "+994 12 312 34 56"
            // },
            {
                "name": "Bahamas",
                "iso2": "bs",
                "dialCode": "1",
                "priority": 1,
                "areaCodes": [
                    "242"
                ],
                "htmlId": "iti-0__item-bs",
                "flagClass": "iti__bs",
                "placeHolder": "+1 242-345-6789"
            },
            {
                "name": "Bahrain (‫البحرين‬‎)",
                "iso2": "bh",
                "dialCode": "973",
                "priority": 0,
                "htmlId": "iti-0__item-bh",
                "flagClass": "iti__bh",
                "placeHolder": "+973 1700 1234"
            },
            {
                "name": "Bangladesh (বাংলাদেশ)",
                "iso2": "bd",
                "dialCode": "880",
                "priority": 0,
                "htmlId": "iti-0__item-bd",
                "flagClass": "iti__bd",
                "placeHolder": "+880 2-7111234"
            },
            // {
            //     "name": "Barbados",
            //     "iso2": "bb",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "246"
            //     ],
            //     "htmlId": "iti-0__item-bb",
            //     "flagClass": "iti__bb",
            //     "placeHolder": "+1 246-412-3456"
            // },
            {
                "name": "Belarus (Беларусь)",
                "iso2": "by",
                "dialCode": "375",
                "priority": 0,
                "htmlId": "iti-0__item-by",
                "flagClass": "iti__by",
                "placeHolder": "+375 152 45-09-11"
            },
            {
                "name": "Belgium (België)",
                "iso2": "be",
                "dialCode": "32",
                "priority": 0,
                "htmlId": "iti-0__item-be",
                "flagClass": "iti__be",
                "placeHolder": "+32 12 34 56 78"
            },
            // {
            //     "name": "Belize",
            //     "iso2": "bz",
            //     "dialCode": "501",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bz",
            //     "flagClass": "iti__bz",
            //     "placeHolder": "+501 222-1234"
            // },
            // {
            //     "name": "Benin (Bénin)",
            //     "iso2": "bj",
            //     "dialCode": "229",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bj",
            //     "flagClass": "iti__bj",
            //     "placeHolder": "+229 20 21 12 34"
            // },
            // {
            //     "name": "Bermuda",
            //     "iso2": "bm",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "441"
            //     ],
            //     "htmlId": "iti-0__item-bm",
            //     "flagClass": "iti__bm",
            //     "placeHolder": "+1 441-412-3456"
            // },
            // {
            //     "name": "Bhutan (འབྲུག)",
            //     "iso2": "bt",
            //     "dialCode": "975",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bt",
            //     "flagClass": "iti__bt",
            //     "placeHolder": "+975 2 345 678"
            // },
            // {
            //     "name": "Bolivia",
            //     "iso2": "bo",
            //     "dialCode": "591",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bo",
            //     "flagClass": "iti__bo",
            //     "placeHolder": "+591 2 2123456"
            // },
            {
                "name": "Bosnia and Herzegovina (Босна и Херцеговина)",
                "iso2": "ba",
                "dialCode": "387",
                "priority": 0,
                "htmlId": "iti-0__item-ba",
                "flagClass": "iti__ba",
                "placeHolder": "+387 30 212-345"
            },
            // {
            //     "name": "Bosnia and Herzegovina (Босна и Херцеговина)",
            //     "iso2": "ba",
            //     "dialCode": "387",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ba",
            //     "flagClass": "iti__ba",
            //     "placeHolder": "+387 30 212-345"
            // },
            // {
            //     "name": "Botswana",
            //     "iso2": "bw",
            //     "dialCode": "267",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bw",
            //     "flagClass": "iti__bw",
            //     "placeHolder": "+267 240 1234"
            // },
            {
                "name": "Brazil (Brasil)",
                "iso2": "br",
                "dialCode": "55",
                "priority": 0,
                "htmlId": "iti-0__item-br",
                "flagClass": "iti__br",
                "placeHolder": "+55 11 2345-6789"
            },
            // {
            //     "name": "British Indian Ocean Territory",
            //     "iso2": "io",
            //     "dialCode": "246",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-io",
            //     "flagClass": "iti__io",
            //     "placeHolder": "+246 370 9100"
            // },
            // {
            //     "name": "British Virgin Islands",
            //     "iso2": "vg",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "284"
            //     ],
            //     "htmlId": "iti-0__item-vg",
            //     "flagClass": "iti__vg",
            //     "placeHolder": "+1 284-229-1234"
            // },
            // {
            //     "name": "Brunei",
            //     "iso2": "bn",
            //     "dialCode": "673",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bn",
            //     "flagClass": "iti__bn",
            //     "placeHolder": "+673 234 5678"
            // },
            // {
            //     "name": "Bulgaria (България)",
            //     "iso2": "bg",
            //     "dialCode": "359",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bg",
            //     "flagClass": "iti__bg",
            //     "placeHolder": "+359 2 123 456"
            // },
            // {
            //     "name": "Burkina Faso",
            //     "iso2": "bf",
            //     "dialCode": "226",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bf",
            //     "flagClass": "iti__bf",
            //     "placeHolder": "+226 20 49 12 34"
            // },
            // {
            //     "name": "Burundi (Uburundi)",
            //     "iso2": "bi",
            //     "dialCode": "257",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-bi",
            //     "flagClass": "iti__bi",
            //     "placeHolder": "+257 22 20 12 34"
            // },
            // {
            //     "name": "Cambodia (កម្ពុជា)",
            //     "iso2": "kh",
            //     "dialCode": "855",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kh",
            //     "flagClass": "iti__kh",
            //     "placeHolder": "+855 23 756 789"
            // },
            // {
            //     "name": "Cameroon (Cameroun)",
            //     "iso2": "cm",
            //     "dialCode": "237",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cm",
            //     "flagClass": "iti__cm",
            //     "placeHolder": "+237 2 22 12 34 56"
            // },
            // {
            //     "name": "Canada",
            //     "iso2": "ca",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "204",
            //         "226",
            //         "236",
            //         "249",
            //         "250",
            //         "289",
            //         "306",
            //         "343",
            //         "365",
            //         "387",
            //         "403",
            //         "416",
            //         "418",
            //         "431",
            //         "437",
            //         "438",
            //         "450",
            //         "506",
            //         "514",
            //         "519",
            //         "548",
            //         "579",
            //         "581",
            //         "587",
            //         "604",
            //         "613",
            //         "639",
            //         "647",
            //         "672",
            //         "705",
            //         "709",
            //         "742",
            //         "778",
            //         "780",
            //         "782",
            //         "807",
            //         "819",
            //         "825",
            //         "867",
            //         "873",
            //         "902",
            //         "905"
            //     ],
            //     "htmlId": "iti-0__item-ca",
            //     "flagClass": "iti__ca",
            //     "placeHolder": "+1 506-234-5678"
            // },
            // {
            //     "name": "Cape Verde (Kabu Verdi)",
            //     "iso2": "cv",
            //     "dialCode": "238",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cv",
            //     "flagClass": "iti__cv",
            //     "placeHolder": "+238 221 12 34"
            // },
            // {
            //     "name": "Caribbean Netherlands",
            //     "iso2": "bq",
            //     "dialCode": "599",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-bq",
            //     "flagClass": "iti__bq",
            //     "placeHolder": "+599 715 1234"
            // },
            {
                "name": "Cayman Islands",
                "iso2": "ky",
                "dialCode": "1",
                "priority": 1,
                "areaCodes": [
                    "345"
                ],
                "htmlId": "iti-0__item-ky",
                "flagClass": "iti__ky",
                "placeHolder": "+1 345-222-1234"
            },
            // {
            //     "name": "Central African Republic (République centrafricaine)",
            //     "iso2": "cf",
            //     "dialCode": "236",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cf",
            //     "flagClass": "iti__cf",
            //     "placeHolder": "+236 21 61 23 45"
            // },
            // {
            //     "name": "Chad (Tchad)",
            //     "iso2": "td",
            //     "dialCode": "235",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-td",
            //     "flagClass": "iti__td",
            //     "placeHolder": "+235 22 50 12 34"
            // },
            // {
            //     "name": "Chile",
            //     "iso2": "cl",
            //     "dialCode": "56",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cl",
            //     "flagClass": "iti__cl",
            //     "placeHolder": "+56 2 2123 4567"
            // },
            // {
            //     "name": "China (中国)",
            //     "iso2": "cn",
            //     "dialCode": "86",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cn",
            //     "flagClass": "iti__cn",
            //     "placeHolder": "+86 10 1234 5678"
            // },
            // {
            //     "name": "Christmas Island",
            //     "iso2": "cx",
            //     "dialCode": "61",
            //     "priority": 2,
            //     "htmlId": "iti-0__item-cx",
            //     "flagClass": "iti__cx",
            //     "placeHolder": "+61 8 9164 1234"
            // },
            // {
            //     "name": "Cocos (Keeling) Islands",
            //     "iso2": "cc",
            //     "dialCode": "61",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-cc",
            //     "flagClass": "iti__cc",
            //     "placeHolder": "+61 8 9162 1234"
            // },
            {
                "name": "Colombia",
                "iso2": "co",
                "dialCode": "57",
                "priority": 0,
                "htmlId": "iti-0__item-co",
                "flagClass": "iti__co",
                "placeHolder": "+57 601 2345678"
            },
            // {
            //     "name": "Comoros (‫جزر القمر‬‎)",
            //     "iso2": "km",
            //     "dialCode": "269",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-km",
            //     "flagClass": "iti__km",
            //     "placeHolder": "+269 771 23 45"
            // },
            // {
            //     "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
            //     "iso2": "cd",
            //     "dialCode": "243",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cd",
            //     "flagClass": "iti__cd",
            //     "placeHolder": "+243 12 34567"
            // },
            // {
            //     "name": "Congo (Republic) (Congo-Brazzaville)",
            //     "iso2": "cg",
            //     "dialCode": "242",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cg",
            //     "flagClass": "iti__cg",
            //     "placeHolder": "+242 22 212 3456"
            // },
            // {
            //     "name": "Cook Islands",
            //     "iso2": "ck",
            //     "dialCode": "682",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ck",
            //     "flagClass": "iti__ck",
            //     "placeHolder": "+682 21 234"
            // },
            // {
            //     "name": "Costa Rica",
            //     "iso2": "cr",
            //     "dialCode": "506",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cr",
            //     "flagClass": "iti__cr",
            //     "placeHolder": "+506 2212 3456"
            // },
            // {
            //     "name": "Côte d’Ivoire",
            //     "iso2": "ci",
            //     "dialCode": "225",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ci",
            //     "flagClass": "iti__ci",
            //     "placeHolder": "+225 21 23 4 56789"
            // },
            {
                "name": "Croatia (Hrvatska)",
                "iso2": "hr",
                "dialCode": "385",
                "priority": 0,
                "htmlId": "iti-0__item-hr",
                "flagClass": "iti__hr",
                "placeHolder": "+385 1 2345 678"
            },
            // {
            //     "name": "Cuba",
            //     "iso2": "cu",
            //     "dialCode": "53",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cu",
            //     "flagClass": "iti__cu",
            //     "placeHolder": "+53 7 1234567"
            // },
            // {
            //     "name": "Curaçao",
            //     "iso2": "cw",
            //     "dialCode": "599",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-cw",
            //     "flagClass": "iti__cw",
            //     "placeHolder": "+599 9 435 1234"
            // },
            {
                "name": "Cyprus (Κύπρος)",
                "iso2": "cy",
                "dialCode": "357",
                "priority": 0,
                "htmlId": "iti-0__item-cy",
                "flagClass": "iti__cy",
                "placeHolder": "+357 22 345678"
            },
            {
                "name": "Czech Republic (Česká republika)",
                "iso2": "cz",
                "dialCode": "420",
                "priority": 0,
                "htmlId": "iti-0__item-cz",
                "flagClass": "iti__cz",
                "placeHolder": "+420 212 345 678"
            },
            {
                "name": "Denmark (Danmark)",
                "iso2": "dk",
                "dialCode": "45",
                "priority": 0,
                "htmlId": "iti-0__item-dk",
                "flagClass": "iti__dk",
                "placeHolder": "+45 32 12 34 56"
            },
            // {
            //     "name": "Djibouti",
            //     "iso2": "dj",
            //     "dialCode": "253",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-dj",
            //     "flagClass": "iti__dj",
            //     "placeHolder": "+253 21 36 00 03"
            // },
            // {
            //     "name": "Dominica",
            //     "iso2": "dm",
            //     "dialCode": "1767",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-dm",
            //     "flagClass": "iti__dm",
            //     "placeHolder": "+1 767-420-1234"
            // },
            // {
            //     "name": "Dominican Republic (República Dominicana)",
            //     "iso2": "do",
            //     "dialCode": "1",
            //     "priority": 2,
            //     "areaCodes": [
            //         "809",
            //         "829",
            //         "849"
            //     ],
            //     "htmlId": "iti-0__item-do",
            //     "flagClass": "iti__do",
            //     "placeHolder": "+1 809-234-5678"
            // },
            // {
            //     "name": "Ecuador",
            //     "iso2": "ec",
            //     "dialCode": "593",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ec",
            //     "flagClass": "iti__ec",
            //     "placeHolder": "+593 2-212-3456"
            // },
            // {
            //     "name": "Egypt (‫مصر‬‎)",
            //     "iso2": "eg",
            //     "dialCode": "20",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-eg",
            //     "flagClass": "iti__eg",
            //     "placeHolder": "+20 2 34567890"
            // },
            // {
            //     "name": "El Salvador",
            //     "iso2": "sv",
            //     "dialCode": "503",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sv",
            //     "flagClass": "iti__sv",
            //     "placeHolder": "+503 2123 4567"
            // },
            // {
            //     "name": "Equatorial Guinea (Guinea Ecuatorial)",
            //     "iso2": "gq",
            //     "dialCode": "240",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gq",
            //     "flagClass": "iti__gq",
            //     "placeHolder": "+240 333 091 234"
            // },
            // {
            //     "name": "Eritrea",
            //     "iso2": "er",
            //     "dialCode": "291",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-er",
            //     "flagClass": "iti__er",
            //     "placeHolder": "+291 8 370 362"
            // },
            {
                "name": "Estonia (Eesti)",
                "iso2": "ee",
                "dialCode": "372",
                "priority": 0,
                "htmlId": "iti-0__item-ee",
                "flagClass": "iti__ee",
                "placeHolder": "+372 321 2345"
            },
            // {
            //     "name": "Ethiopia",
            //     "iso2": "et",
            //     "dialCode": "251",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-et",
            //     "flagClass": "iti__et",
            //     "placeHolder": "+251 11 111 2345"
            // },
            // {
            //     "name": "Falkland Islands (Islas Malvinas)",
            //     "iso2": "fk",
            //     "dialCode": "500",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-fk",
            //     "flagClass": "iti__fk",
            //     "placeHolder": "+500 31234"
            // },
            // {
            //     "name": "Faroe Islands (Føroyar)",
            //     "iso2": "fo",
            //     "dialCode": "298",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-fo",
            //     "flagClass": "iti__fo",
            //     "placeHolder": "+298 201234"
            // },
            // {
            //     "name": "Fiji",
            //     "iso2": "fj",
            //     "dialCode": "679",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-fj",
            //     "flagClass": "iti__fj",
            //     "placeHolder": "+679 321 2345"
            // },
            {
                "name": "Finland (Suomi)",
                "iso2": "fi",
                "dialCode": "358",
                "priority": 0,
                "htmlId": "iti-0__item-fi",
                "flagClass": "iti__fi",
                "placeHolder": "+358 13 1234567"
            },
            {
                "name": "France",
                "iso2": "fr",
                "dialCode": "33",
                "priority": 0,
                "htmlId": "iti-0__item-fr",
                "flagClass": "iti__fr",
                "placeHolder": "+33 1 23 45 67 89"
            },
            // {
            //     "name": "French Guiana (Guyane française)",
            //     "iso2": "gf",
            //     "dialCode": "594",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gf",
            //     "flagClass": "iti__gf",
            //     "placeHolder": "+594 594 10 12 34"
            // },
            // {
            //     "name": "French Polynesia (Polynésie française)",
            //     "iso2": "pf",
            //     "dialCode": "689",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pf",
            //     "flagClass": "iti__pf",
            //     "placeHolder": "+689 40 41 23 45"
            // },
            // {
            //     "name": "Gabon",
            //     "iso2": "ga",
            //     "dialCode": "241",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ga",
            //     "flagClass": "iti__ga",
            //     "placeHolder": "+241 01 44 12 34"
            // },
            // {
            //     "name": "Gambia",
            //     "iso2": "gm",
            //     "dialCode": "220",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gm",
            //     "flagClass": "iti__gm",
            //     "placeHolder": "+220 566 1234"
            // },
            {
                "name": "Georgia (საქართველო)",
                "iso2": "ge",
                "dialCode": "995",
                "priority": 0,
                "htmlId": "iti-0__item-ge",
                "flagClass": "iti__ge",
                "placeHolder": "+995 32 212 34 56"
            },
            {
                "name": "Germany (Deutschland)",
                "iso2": "de",
                "dialCode": "49",
                "priority": 0,
                "htmlId": "iti-0__item-de",
                "flagClass": "iti__de",
                "placeHolder": "+49 30 123456"
            },
            // {
            //     "name": "Ghana (Gaana)",
            //     "iso2": "gh",
            //     "dialCode": "233",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gh",
            //     "flagClass": "iti__gh",
            //     "placeHolder": "+233 30 234 5678"
            // },
            // {
            //     "name": "Gibraltar",
            //     "iso2": "gi",
            //     "dialCode": "350",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gi",
            //     "flagClass": "iti__gi",
            //     "placeHolder": "+350 200 12345"
            // },
            {
                "name": "Greece (Ελλάδα)",
                "iso2": "gr",
                "dialCode": "30",
                "priority": 0,
                "htmlId": "iti-0__item-gr",
                "flagClass": "iti__gr",
                "placeHolder": "+30 21 2345 6789"
            },
            {
                "name": "Greenland (Kalaallit Nunaat)",
                "iso2": "gl",
                "dialCode": "299",
                "priority": 0,
                "htmlId": "iti-0__item-gl",
                "flagClass": "iti__gl",
                "placeHolder": "+299 32 10 00"
            },
            // {
            //     "name": "Grenada",
            //     "iso2": "gd",
            //     "dialCode": "1473",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gd",
            //     "flagClass": "iti__gd",
            //     "placeHolder": "+1 473-269-1234"
            // },
            // {
            //     "name": "Guadeloupe",
            //     "iso2": "gp",
            //     "dialCode": "590",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gp",
            //     "flagClass": "iti__gp",
            //     "placeHolder": "+590 590 20 12 34"
            // },
            // {
            //     "name": "Guam",
            //     "iso2": "gu",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "671"
            //     ],
            //     "htmlId": "iti-0__item-gu",
            //     "flagClass": "iti__gu",
            //     "placeHolder": "+1 671-300-1234"
            // },
            // {
            //     "name": "Guatemala",
            //     "iso2": "gt",
            //     "dialCode": "502",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gt",
            //     "flagClass": "iti__gt",
            //     "placeHolder": "+502 2245 6789"
            // },
            // {
            //     "name": "Guernsey",
            //     "iso2": "gg",
            //     "dialCode": "44",
            //     "priority": 1,
            //     "areaCodes": [
            //         1481
            //     ],
            //     "htmlId": "iti-0__item-gg",
            //     "flagClass": "iti__gg",
            //     "placeHolder": "+44 1481 256789"
            // },
            // {
            //     "name": "Guinea (Guinée)",
            //     "iso2": "gn",
            //     "dialCode": "224",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gn",
            //     "flagClass": "iti__gn",
            //     "placeHolder": "+224 30 24 12 34"
            // },
            // {
            //     "name": "Guinea-Bissau (Guiné Bissau)",
            //     "iso2": "gw",
            //     "dialCode": "245",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gw",
            //     "flagClass": "iti__gw",
            //     "placeHolder": "+245 443 201 234"
            // },
            // {
            //     "name": "Guyana",
            //     "iso2": "gy",
            //     "dialCode": "592",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-gy",
            //     "flagClass": "iti__gy",
            //     "placeHolder": "+592 220 1234"
            // },
            // {
            //     "name": "Haiti",
            //     "iso2": "ht",
            //     "dialCode": "509",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ht",
            //     "flagClass": "iti__ht",
            //     "placeHolder": "+509 22 45 3300"
            // },
            // {
            //     "name": "Honduras",
            //     "iso2": "hn",
            //     "dialCode": "504",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-hn",
            //     "flagClass": "iti__hn",
            //     "placeHolder": "+504 2212-3456"
            // },
            // {
            //     "name": "Hong Kong (香港)",
            //     "iso2": "hk",
            //     "dialCode": "852",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-hk",
            //     "flagClass": "iti__hk",
            //     "placeHolder": "+852 2123 4567"
            // },
            {
                "name": "Hungary (Magyarország)",
                "iso2": "hu",
                "dialCode": "36",
                "priority": 0,
                "htmlId": "iti-0__item-hu",
                "flagClass": "iti__hu",
                "placeHolder": "+36 1 234 5678"
            },
            // {
            //     "name": "Iceland (Ísland)",
            //     "iso2": "is",
            //     "dialCode": "354",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-is",
            //     "flagClass": "iti__is",
            //     "placeHolder": "+354 410 1234"
            // },
            // {
            //     "name": "India (भारत)",
            //     "iso2": "in",
            //     "dialCode": "91",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-in",
            //     "flagClass": "iti__in",
            //     "placeHolder": "+91 74104 10123"
            // },
            // {
            //     "name": "Indonesia",
            //     "iso2": "id",
            //     "dialCode": "62",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-id",
            //     "flagClass": "iti__id",
            //     "placeHolder": "+62 21 8350123"
            // },
            // {
            //     "name": "Iran (‫ایران‬‎)",
            //     "iso2": "ir",
            //     "dialCode": "98",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ir",
            //     "flagClass": "iti__ir",
            //     "placeHolder": "+98 21 2345 6789"
            // },
            // {
            //     "name": "Iraq (‫العراق‬‎)",
            //     "iso2": "iq",
            //     "dialCode": "964",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-iq",
            //     "flagClass": "iti__iq",
            //     "placeHolder": "+964 1 234 5678"
            // },
            {
                "name": "Ireland",
                "iso2": "ie",
                "dialCode": "353",
                "priority": 0,
                "htmlId": "iti-0__item-ie",
                "flagClass": "iti__ie",
                "placeHolder": "+353 22 12345"
            },
            // {
            //     "name": "Isle of Man",
            //     "iso2": "im",
            //     "dialCode": "44",
            //     "priority": 2,
            //     "areaCodes": [
            //         1624
            //     ],
            //     "htmlId": "iti-0__item-im",
            //     "flagClass": "iti__im",
            //     "placeHolder": "+44 1624 756789"
            // },
            {
                "name": "Israel (‫ישראל‬‎)",
                "iso2": "il",
                "dialCode": "972",
                "priority": 0,
                "htmlId": "iti-0__item-il",
                "flagClass": "iti__il",
                "placeHolder": "+972 2-123-4567"
            },
            {
                "name": "Italy (Italia)",
                "iso2": "it",
                "dialCode": "39",
                "priority": 0,
                "htmlId": "iti-0__item-it",
                "flagClass": "iti__it",
                "placeHolder": "+39 02 1234 5678"
            },
            // {
            //     "name": "Jamaica",
            //     "iso2": "jm",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "876"
            //     ],
            //     "htmlId": "iti-0__item-jm",
            //     "flagClass": "iti__jm",
            //     "placeHolder": "+1 876-523-0123"
            // },
            // {
            //     "name": "Jamaica",
            //     "iso2": "jm",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "876"
            //     ],
            //     "htmlId": "iti-0__item-jm",
            //     "flagClass": "iti__jm",
            //     "placeHolder": "+1 876-523-0123"
            // },
            // {
            //     "name": "Japan (日本)",
            //     "iso2": "jp",
            //     "dialCode": "81",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-jp",
            //     "flagClass": "iti__jp",
            //     "placeHolder": "+81 3-1234-5678"
            // },
            // {
            //     "name": "Jersey",
            //     "iso2": "je",
            //     "dialCode": "44",
            //     "priority": 3,
            //     "areaCodes": [
            //         1534
            //     ],
            //     "htmlId": "iti-0__item-je",
            //     "flagClass": "iti__je",
            //     "placeHolder": "+44 1534 456789"
            // },
            // {
            //     "name": "Jordan (‫الأردن‬‎)",
            //     "iso2": "jo",
            //     "dialCode": "962",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-jo",
            //     "flagClass": "iti__jo",
            //     "placeHolder": "+962 6 200 1234"
            // },
            // {
            //     "name": "Kazakhstan (Казахстан)",
            //     "iso2": "kz",
            //     "dialCode": "7",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-kz",
            //     "flagClass": "iti__kz",
            //     "placeHolder": "+7 71234 5 67 89"
            // },
            // {
            //     "name": "Kenya",
            //     "iso2": "ke",
            //     "dialCode": "254",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ke",
            //     "flagClass": "iti__ke",
            //     "placeHolder": "+254 20 2012345"
            // },
            // {
            //     "name": "Kiribati",
            //     "iso2": "ki",
            //     "dialCode": "686",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ki",
            //     "flagClass": "iti__ki",
            //     "placeHolder": "+686 31234"
            // },
            // {
            //     "name": "Kosovo",
            //     "iso2": "xk",
            //     "dialCode": "383",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-xk",
            //     "flagClass": "iti__xk",
            //     "placeHolder": "+383 28 012 345"
            // },
            // {
            //     "name": "Kuwait (‫الكويت‬‎)",
            //     "iso2": "kw",
            //     "dialCode": "965",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kw",
            //     "flagClass": "iti__kw",
            //     "placeHolder": "+965 2234 5678"
            // },
            // {
            //     "name": "Kyrgyzstan (Кыргызстан)",
            //     "iso2": "kg",
            //     "dialCode": "996",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kg",
            //     "flagClass": "iti__kg",
            //     "placeHolder": "+996 312 123 456"
            // },
            // {
            //     "name": "Laos (ລາວ)",
            //     "iso2": "la",
            //     "dialCode": "856",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-la",
            //     "flagClass": "iti__la",
            //     "placeHolder": "+856 21 212 862"
            // },
            {
                "name": "Latvia (Latvija)",
                "iso2": "lv",
                "dialCode": "371",
                "priority": 0,
                "htmlId": "iti-0__item-lv",
                "flagClass": "iti__lv",
                "placeHolder": "+371 63 123 456"
            },
            // {
            //     "name": "Lebanon (‫لبنان‬‎)",
            //     "iso2": "lb",
            //     "dialCode": "961",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-lb",
            //     "flagClass": "iti__lb",
            //     "placeHolder": "+961 1 123 456"
            // },
            // {
            //     "name": "Lesotho",
            //     "iso2": "ls",
            //     "dialCode": "266",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ls",
            //     "flagClass": "iti__ls",
            //     "placeHolder": "+266 2212 3456"
            // },
            // {
            //     "name": "Liberia",
            //     "iso2": "lr",
            //     "dialCode": "231",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-lr",
            //     "flagClass": "iti__lr",
            //     "placeHolder": "+231 21 234 567"
            // },
            // {
            //     "name": "Libya (‫ليبيا‬‎)",
            //     "iso2": "ly",
            //     "dialCode": "218",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ly",
            //     "flagClass": "iti__ly",
            //     "placeHolder": "+218 21-2345678"
            // },
            // {
            //     "name": "Liechtenstein",
            //     "iso2": "li",
            //     "dialCode": "423",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-li",
            //     "flagClass": "iti__li",
            //     "placeHolder": "+423 234 56 78"
            // },
            {
                "name": "Lithuania (Lietuva)",
                "iso2": "lt",
                "dialCode": "370",
                "priority": 0,
                "htmlId": "iti-0__item-lt",
                "flagClass": "iti__lt",
                "placeHolder": "+370 312 34567"
            },
            {
                "name": "Luxembourg",
                "iso2": "lu",
                "dialCode": "352",
                "priority": 0,
                "htmlId": "iti-0__item-lu",
                "flagClass": "iti__lu",
                "placeHolder": "+352 27 12 34 56"
            },
            {
                "name": "Macau (澳門)",
                "iso2": "mo",
                "dialCode": "853",
                "priority": 0,
                "htmlId": "iti-0__item-mo",
                "flagClass": "iti__mo",
                "placeHolder": "+853 2821 2345"
            },
            // {
            //     "name": "Macedonia (FYROM) (Македонија)",
            //     "iso2": "mk",
            //     "dialCode": "389",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mk",
            //     "flagClass": "iti__mk",
            //     "placeHolder": "+389 2 201 2345"
            // },
            // {
            //     "name": "Madagascar (Madagasikara)",
            //     "iso2": "mg",
            //     "dialCode": "261",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mg",
            //     "flagClass": "iti__mg",
            //     "placeHolder": "+261 20 21 234 56"
            // },
            // {
            //     "name": "Malawi",
            //     "iso2": "mw",
            //     "dialCode": "265",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mw",
            //     "flagClass": "iti__mw",
            //     "placeHolder": "+265 1 234 567"
            // },
            // {
            //     "name": "Malaysia",
            //     "iso2": "my",
            //     "dialCode": "60",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-my",
            //     "flagClass": "iti__my",
            //     "placeHolder": "+60 3-2385 6789"
            // },
            // {
            //     "name": "Maldives",
            //     "iso2": "mv",
            //     "dialCode": "960",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mv",
            //     "flagClass": "iti__mv",
            //     "placeHolder": "+960 670-1234"
            // },
            // {
            //     "name": "Mali",
            //     "iso2": "ml",
            //     "dialCode": "223",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ml",
            //     "flagClass": "iti__ml",
            //     "placeHolder": "+223 20 21 23 45"
            // },
            {
                "name": "Malta",
                "iso2": "mt",
                "dialCode": "356",
                "priority": 0,
                "htmlId": "iti-0__item-mt",
                "flagClass": "iti__mt",
                "placeHolder": "+356 2100 1234"
            },
            // {
            //     "name": "Marshall Islands",
            //     "iso2": "mh",
            //     "dialCode": "692",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mh",
            //     "flagClass": "iti__mh",
            //     "placeHolder": "+692 247-1234"
            // },
            // {
            //     "name": "Martinique",
            //     "iso2": "mq",
            //     "dialCode": "596",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mq",
            //     "flagClass": "iti__mq",
            //     "placeHolder": "+596 596 30 12 34"
            // },
            // {
            //     "name": "Mauritania (‫موريتانيا‬‎)",
            //     "iso2": "mr",
            //     "dialCode": "222",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mr",
            //     "flagClass": "iti__mr",
            //     "placeHolder": "+222 35 12 34 56"
            // },
            // {
            //     "name": "Mauritius (Moris)",
            //     "iso2": "mu",
            //     "dialCode": "230",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mu",
            //     "flagClass": "iti__mu",
            //     "placeHolder": "+230 5448 0123"
            // },
            // {
            //     "name": "Mayotte",
            //     "iso2": "yt",
            //     "dialCode": "262",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-yt",
            //     "flagClass": "iti__yt",
            //     "placeHolder": "+262 269 60 12 34"
            // },
            // {
            //     "name": "Mexico (México)",
            //     "iso2": "mx",
            //     "dialCode": "52",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mx",
            //     "flagClass": "iti__mx",
            //     "placeHolder": "+52 200 123 4567"
            // },
            // {
            //     "name": "Micronesia",
            //     "iso2": "fm",
            //     "dialCode": "691",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-fm",
            //     "flagClass": "iti__fm",
            //     "placeHolder": "+691 320 1234"
            // },
            {
                "name": "Moldova (Republica Moldova)",
                "iso2": "md",
                "dialCode": "373",
                "priority": 0,
                "htmlId": "iti-0__item-md",
                "flagClass": "iti__md",
                "placeHolder": "+373 22 212 345"
            },
            // {
            //     "name": "Monaco",
            //     "iso2": "mc",
            //     "dialCode": "377",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mc",
            //     "flagClass": "iti__mc",
            //     "placeHolder": "+377 99 12 34 56"
            // },
            // {
            //     "name": "Mongolia (Монгол)",
            //     "iso2": "mn",
            //     "dialCode": "976",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mn",
            //     "flagClass": "iti__mn",
            //     "placeHolder": "+976 5312 3456"
            // },
            {
                "name": "Montenegro (Crna Gora)",
                "iso2": "me",
                "dialCode": "382",
                "priority": 0,
                "htmlId": "iti-0__item-me",
                "flagClass": "iti__me",
                "placeHolder": "+382 30 234 567"
            },
            // {
            //     "name": "Montserrat",
            //     "iso2": "ms",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "664"
            //     ],
            //     "htmlId": "iti-0__item-ms",
            //     "flagClass": "iti__ms",
            //     "placeHolder": "+1 664-491-2345"
            // },
            // {
            //     "name": "Morocco (‫المغرب‬‎)",
            //     "iso2": "ma",
            //     "dialCode": "212",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ma",
            //     "flagClass": "iti__ma",
            //     "placeHolder": "+212 520-123456"
            // },
            // {
            //     "name": "Mozambique (Moçambique)",
            //     "iso2": "mz",
            //     "dialCode": "258",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mz",
            //     "flagClass": "iti__mz",
            //     "placeHolder": "+258 21 123 456"
            // },
            // {
            //     "name": "Myanmar (Burma) (မြန်မာ)",
            //     "iso2": "mm",
            //     "dialCode": "95",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mm",
            //     "flagClass": "iti__mm",
            //     "placeHolder": "+95 1 234 567"
            // },
            // {
            //     "name": "Namibia (Namibië)",
            //     "iso2": "na",
            //     "dialCode": "264",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-na",
            //     "flagClass": "iti__na",
            //     "placeHolder": "+264 61 221 234"
            // },
            // {
            //     "name": "Nauru",
            //     "iso2": "nr",
            //     "dialCode": "674",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-nr",
            //     "flagClass": "iti__nr",
            //     "placeHolder": "+674 444 1234"
            // },
            // {
            //     "name": "Nepal (नेपाल)",
            //     "iso2": "np",
            //     "dialCode": "977",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-np",
            //     "flagClass": "iti__np",
            //     "placeHolder": "+977 1-4567890"
            // },
            {
                "name": "Netherlands (Nederland)",
                "iso2": "nl",
                "dialCode": "31",
                "priority": 0,
                "htmlId": "iti-0__item-nl",
                "flagClass": "iti__nl",
                "placeHolder": "+31 10 123 4567"
            },
            // {
            //     "name": "New Caledonia (Nouvelle-Calédonie)",
            //     "iso2": "nc",
            //     "dialCode": "687",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-nc",
            //     "flagClass": "iti__nc",
            //     "placeHolder": "+687 20.12.34"
            // },
            {
                "name": "New Zealand",
                "iso2": "nz",
                "dialCode": "64",
                "priority": 0,
                "htmlId": "iti-0__item-nz",
                "flagClass": "iti__nz",
                "placeHolder": "+64 3 234 5678"
            },
            // {
            //     "name": "Nicaragua",
            //     "iso2": "ni",
            //     "dialCode": "505",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ni",
            //     "flagClass": "iti__ni",
            //     "placeHolder": "+505 2123 4567"
            // },
            // {
            //     "name": "Niger (Nijar)",
            //     "iso2": "ne",
            //     "dialCode": "227",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ne",
            //     "flagClass": "iti__ne",
            //     "placeHolder": "+227 20 20 12 34"
            // },
            // {
            //     "name": "Nigeria",
            //     "iso2": "ng",
            //     "dialCode": "234",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ng",
            //     "flagClass": "iti__ng",
            //     "placeHolder": "+234 1 804 0123"
            // },
            // {
            //     "name": "Niue",
            //     "iso2": "nu",
            //     "dialCode": "683",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-nu",
            //     "flagClass": "iti__nu",
            //     "placeHolder": "+683 7012"
            // },
            // {
            //     "name": "Norfolk Island",
            //     "iso2": "nf",
            //     "dialCode": "672",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-nf",
            //     "flagClass": "iti__nf",
            //     "placeHolder": "+672 10 6609"
            // },
            // {
            //     "name": "North Korea (조선 민주주의 인민 공화국)",
            //     "iso2": "kp",
            //     "dialCode": "850",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kp",
            //     "flagClass": "iti__kp",
            //     "placeHolder": "+850 2 123 4567"
            // },
            // {
            //     "name": "Northern Mariana Islands",
            //     "iso2": "mp",
            //     "dialCode": "1670",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-mp",
            //     "flagClass": "iti__mp",
            //     "placeHolder": "+1 670-234-5678"
            // },
            {
                "name": "Norway (Norge)",
                "iso2": "no",
                "dialCode": "47",
                "priority": 0,
                "htmlId": "iti-0__item-no",
                "flagClass": "iti__no",
                "placeHolder": "+47 21 23 45 67"
            },
            // {
            //     "name": "Oman (‫عُمان‬‎)",
            //     "iso2": "om",
            //     "dialCode": "968",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-om",
            //     "flagClass": "iti__om",
            //     "placeHolder": "+968 23 123456"
            // },
            // {
            //     "name": "Pakistan (‫پاکستان‬‎)",
            //     "iso2": "pk",
            //     "dialCode": "92",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pk",
            //     "flagClass": "iti__pk",
            //     "placeHolder": "+92 21 23456789"
            // },
            // {
            //     "name": "Palau",
            //     "iso2": "pw",
            //     "dialCode": "680",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pw",
            //     "flagClass": "iti__pw",
            //     "placeHolder": "+680 277 1234"
            // },
            // {
            //     "name": "Palestine (‫فلسطين‬‎)",
            //     "iso2": "ps",
            //     "dialCode": "970",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ps",
            //     "flagClass": "iti__ps",
            //     "placeHolder": "+970 2 223 4567"
            // },
            // {
            //     "name": "Panama (Panamá)",
            //     "iso2": "pa",
            //     "dialCode": "507",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pa",
            //     "flagClass": "iti__pa",
            //     "placeHolder": "+507 200-1234"
            // },
            // {
            //     "name": "Papua New Guinea",
            //     "iso2": "pg",
            //     "dialCode": "675",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pg",
            //     "flagClass": "iti__pg",
            //     "placeHolder": "+675 312 3456"
            // },
            // {
            //     "name": "Paraguay",
            //     "iso2": "py",
            //     "dialCode": "595",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-py",
            //     "flagClass": "iti__py",
            //     "placeHolder": "+595 21 234 5678"
            // },
            // {
            //     "name": "Peru (Perú)",
            //     "iso2": "pe",
            //     "dialCode": "51",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pe",
            //     "flagClass": "iti__pe",
            //     "placeHolder": "+51 1 1234567"
            // },
            // {
            //     "name": "Philippines",
            //     "iso2": "ph",
            //     "dialCode": "63",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ph",
            //     "flagClass": "iti__ph",
            //     "placeHolder": "+63 2 3234 5678"
            // },
            {
                "name": "Poland (Polska)",
                "iso2": "pl",
                "dialCode": "48",
                "priority": 0,
                "htmlId": "iti-0__item-pl",
                "flagClass": "iti__pl",
                "placeHolder": "+48 12 345 67 89"
            },
            {
                "name": "Portugal",
                "iso2": "pt",
                "dialCode": "351",
                "priority": 0,
                "htmlId": "iti-0__item-pt",
                "flagClass": "iti__pt",
                "placeHolder": "+351 21 234 5678"
            },
            // {
            //     "name": "Puerto Rico",
            //     "iso2": "pr",
            //     "dialCode": "1",
            //     "priority": 3,
            //     "areaCodes": [
            //         "787",
            //         "939"
            //     ],
            //     "htmlId": "iti-0__item-pr",
            //     "flagClass": "iti__pr",
            //     "placeHolder": "+1 787-234-5678"
            // },
            // {
            //     "name": "Qatar (‫قطر‬‎)",
            //     "iso2": "qa",
            //     "dialCode": "974",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-qa",
            //     "flagClass": "iti__qa",
            //     "placeHolder": "+974 4412 3456"
            // },
            // {
            //     "name": "Réunion (La Réunion)",
            //     "iso2": "re",
            //     "dialCode": "262",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-re",
            //     "flagClass": "iti__re",
            //     "placeHolder": "+262 262 16 12 34"
            // },
            {
                "name": "Romania (România)",
                "iso2": "ro",
                "dialCode": "40",
                "priority": 0,
                "htmlId": "iti-0__item-ro",
                "flagClass": "iti__ro",
                "placeHolder": "+40 21 123 4567"
            },
            // {
            //     "name": "Russia (Россия)",
            //     "iso2": "ru",
            //     "dialCode": "7",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ru",
            //     "flagClass": "iti__ru",
            //     "placeHolder": "+7 301 123-45-67"
            // },
            // {
            //     "name": "Rwanda",
            //     "iso2": "rw",
            //     "dialCode": "250",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-rw",
            //     "flagClass": "iti__rw",
            //     "placeHolder": "+250 250 123 456"
            // },
            // {
            //     "name": "Saint Barthélemy (Saint-Barthélemy)",
            //     "iso2": "bl",
            //     "dialCode": "590",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-bl",
            //     "flagClass": "iti__bl",
            //     "placeHolder": "+590 590 27 12 34"
            // },
            // {
            //     "name": "Saint Helena",
            //     "iso2": "sh",
            //     "dialCode": "290",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sh",
            //     "flagClass": "iti__sh",
            //     "placeHolder": "+290 22158"
            // },
            // {
            //     "name": "Saint Kitts and Nevis",
            //     "iso2": "kn",
            //     "dialCode": "1869",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kn",
            //     "flagClass": "iti__kn",
            //     "placeHolder": "+1 869-236-1234"
            // },
            // {
            //     "name": "Saint Lucia",
            //     "iso2": "lc",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "758"
            //     ],
            //     "htmlId": "iti-0__item-lc",
            //     "flagClass": "iti__lc",
            //     "placeHolder": "+1 758-430-5678"
            // },
            // {
            //     "name": "Saint Martin (Saint-Martin (partie française))",
            //     "iso2": "mf",
            //     "dialCode": "590",
            //     "priority": 2,
            //     "htmlId": "iti-0__item-mf",
            //     "flagClass": "iti__mf",
            //     "placeHolder": "+590 590 27 12 34"
            // },
            // {
            //     "name": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
            //     "iso2": "pm",
            //     "dialCode": "508",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-pm",
            //     "flagClass": "iti__pm",
            //     "placeHolder": "+508 43 01 23"
            // },
            // {
            //     "name": "Saint Vincent and the Grenadines",
            //     "iso2": "vc",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "784"
            //     ],
            //     "htmlId": "iti-0__item-vc",
            //     "flagClass": "iti__vc",
            //     "placeHolder": "+1 784-266-1234"
            // },
            // {
            //     "name": "Samoa",
            //     "iso2": "ws",
            //     "dialCode": "685",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ws",
            //     "flagClass": "iti__ws",
            //     "placeHolder": "+685 22123"
            // },
            // {
            //     "name": "San Marino",
            //     "iso2": "sm",
            //     "dialCode": "378",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sm",
            //     "flagClass": "iti__sm",
            //     "placeHolder": "+378 0549 886377"
            // },
            // {
            //     "name": "São Tomé and Príncipe (São Tomé e Príncipe)",
            //     "iso2": "st",
            //     "dialCode": "239",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-st",
            //     "flagClass": "iti__st",
            //     "placeHolder": "+239 222 1234"
            // },
            {
                "name": "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
                "iso2": "sa",
                "dialCode": "966",
                "priority": 0,
                "htmlId": "iti-0__item-sa",
                "flagClass": "iti__sa",
                "placeHolder": "+966 11 234 5678"
            },
            // {
            //     "name": "Senegal (Sénégal)",
            //     "iso2": "sn",
            //     "dialCode": "221",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sn",
            //     "flagClass": "iti__sn",
            //     "placeHolder": "+221 30 101 23 45"
            // },
            {
                "name": "Serbia (Србија)",
                "iso2": "rs",
                "dialCode": "381",
                "priority": 0,
                "htmlId": "iti-0__item-rs",
                "flagClass": "iti__rs",
                "placeHolder": "+381 10 234567"
            },
            // {
            //     "name": "Seychelles",
            //     "iso2": "sc",
            //     "dialCode": "248",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sc",
            //     "flagClass": "iti__sc",
            //     "placeHolder": "+248 4 217 123"
            // },
            // {
            //     "name": "Sierra Leone",
            //     "iso2": "sl",
            //     "dialCode": "232",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sl",
            //     "flagClass": "iti__sl",
            //     "placeHolder": "+232 22 221234"
            // },
            {
                "name": "Singapore",
                "iso2": "sg",
                "dialCode": "65",
                "priority": 0,
                "htmlId": "iti-0__item-sg",
                "flagClass": "iti__sg",
                "placeHolder": "+65 6123 4567"
            },
            // {
            //     "name": "Sint Maarten",
            //     "iso2": "sx",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "721"
            //     ],
            //     "htmlId": "iti-0__item-sx",
            //     "flagClass": "iti__sx",
            //     "placeHolder": "+1 721-542-5678"
            // },
            {
                "name": "Slovakia (Slovensko)",
                "iso2": "sk",
                "dialCode": "421",
                "priority": 0,
                "htmlId": "iti-0__item-sk",
                "flagClass": "iti__sk",
                "placeHolder": "+421 2/212 345 67"
            },
            {
                "name": "Slovenia (Slovenija)",
                "iso2": "si",
                "dialCode": "386",
                "priority": 0,
                "htmlId": "iti-0__item-si",
                "flagClass": "iti__si",
                "placeHolder": "+386 1 234 56 78"
            },
            // {
            //     "name": "Solomon Islands",
            //     "iso2": "sb",
            //     "dialCode": "677",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sb",
            //     "flagClass": "iti__sb",
            //     "placeHolder": "+677 40123"
            // },
            // {
            //     "name": "Somalia (Soomaaliya)",
            //     "iso2": "so",
            //     "dialCode": "252",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-so",
            //     "flagClass": "iti__so",
            //     "placeHolder": "+252 4 012345"
            // },
            // {
            //     "name": "South Africa",
            //     "iso2": "za",
            //     "dialCode": "27",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-za",
            //     "flagClass": "iti__za",
            //     "placeHolder": "+27 10 123 4567"
            // },
            // {
            //     "name": "South Korea (대한민국)",
            //     "iso2": "kr",
            //     "dialCode": "82",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-kr",
            //     "flagClass": "iti__kr",
            //     "placeHolder": "+82 2-212-3456"
            // },
            // {
            //     "name": "South Sudan (‫جنوب السودان‬‎)",
            //     "iso2": "ss",
            //     "dialCode": "211",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ss",
            //     "flagClass": "iti__ss",
            //     "placeHolder": "+211 181 234 567"
            // },
            {
                "name": "Spain (España)",
                "iso2": "es",
                "dialCode": "34",
                "priority": 0,
                "htmlId": "iti-0__item-es",
                "flagClass": "iti__es",
                "placeHolder": "+34 810 12 34 56"
            },
            // {
            //     "name": "Sri Lanka (ශ්‍රී ලංකාව)",
            //     "iso2": "lk",
            //     "dialCode": "94",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-lk",
            //     "flagClass": "iti__lk",
            //     "placeHolder": "+94 112 345 678"
            // },
            // {
            //     "name": "Sudan (‫السودان‬‎)",
            //     "iso2": "sd",
            //     "dialCode": "249",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sd",
            //     "flagClass": "iti__sd",
            //     "placeHolder": "+249 15 312 3456"
            // },
            // {
            //     "name": "Suriname",
            //     "iso2": "sr",
            //     "dialCode": "597",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sr",
            //     "flagClass": "iti__sr",
            //     "placeHolder": "+597 211-234"
            // },
            // {
            //     "name": "Svalbard and Jan Mayen",
            //     "iso2": "sj",
            //     "dialCode": "47",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-sj",
            //     "flagClass": "iti__sj",
            //     "placeHolder": "+47 79 12 34 56"
            // },
            {
                "name": "Swaziland",
                "iso2": "sz",
                "dialCode": "268",
                "priority": 0,
                "htmlId": "iti-0__item-sz",
                "flagClass": "iti__sz",
                "placeHolder": "+268 2217 1234"
            },
            {
                "name": "Sweden (Sverige)",
                "iso2": "se",
                "dialCode": "46",
                "priority": 0,
                "htmlId": "iti-0__item-se",
                "flagClass": "iti__se",
                "placeHolder": "+46 8 12 34 56"
            },
            {
                "name": "Switzerland (Schweiz)",
                "iso2": "ch",
                "dialCode": "41",
                "priority": 0,
                "htmlId": "iti-0__item-ch",
                "flagClass": "iti__ch",
                "placeHolder": "+41 21 234 56 78"
            },
            // {
            //     "name": "Syria (‫سوريا‬‎)",
            //     "iso2": "sy",
            //     "dialCode": "963",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-sy",
            //     "flagClass": "iti__sy",
            //     "placeHolder": "+963 11 234 5678"
            // },
            // {
            //     "name": "Taiwan (台灣)",
            //     "iso2": "tw",
            //     "dialCode": "886",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tw",
            //     "flagClass": "iti__tw",
            //     "placeHolder": "+886 2 2123 4567"
            // },
            // {
            //     "name": "Tajikistan",
            //     "iso2": "tj",
            //     "dialCode": "992",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tj",
            //     "flagClass": "iti__tj",
            //     "placeHolder": "+992 372 12 3456"
            // },
            // {
            //     "name": "Tanzania",
            //     "iso2": "tz",
            //     "dialCode": "255",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tz",
            //     "flagClass": "iti__tz",
            //     "placeHolder": "+255 22 234 5678"
            // },
            // {
            //     "name": "Thailand (ไทย)",
            //     "iso2": "th",
            //     "dialCode": "66",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-th",
            //     "flagClass": "iti__th",
            //     "placeHolder": "+66 2 123 4567"
            // },
            // {
            //     "name": "Timor-Leste",
            //     "iso2": "tl",
            //     "dialCode": "670",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tl",
            //     "flagClass": "iti__tl",
            //     "placeHolder": "+670 211 2345"
            // },
            // {
            //     "name": "Togo",
            //     "iso2": "tg",
            //     "dialCode": "228",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tg",
            //     "flagClass": "iti__tg",
            //     "placeHolder": "+228 22 21 23 45"
            // },
            // {
            //     "name": "Tokelau",
            //     "iso2": "tk",
            //     "dialCode": "690",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tk",
            //     "flagClass": "iti__tk",
            //     "placeHolder": "+690 3101"
            // },
            // {
            //     "name": "Tonga",
            //     "iso2": "to",
            //     "dialCode": "676",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-to",
            //     "flagClass": "iti__to",
            //     "placeHolder": "+676 20-123"
            // },
            // {
            //     "name": "Trinidad and Tobago",
            //     "iso2": "tt",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "868"
            //     ],
            //     "htmlId": "iti-0__item-tt",
            //     "flagClass": "iti__tt",
            //     "placeHolder": "+1 868-221-1234"
            // },
            // {
            //     "name": "Tunisia (‫تونس‬‎)",
            //     "iso2": "tn",
            //     "dialCode": "216",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tn",
            //     "flagClass": "iti__tn",
            //     "placeHolder": "+216 30 010 123"
            // },
            // {
            //     "name": "Turkey (Türkiye)",
            //     "iso2": "tr",
            //     "dialCode": "90",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tr",
            //     "flagClass": "iti__tr",
            //     "placeHolder": "+90 212 345 67 89"
            // },
            // {
            //     "name": "Turkmenistan",
            //     "iso2": "tm",
            //     "dialCode": "993",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tm",
            //     "flagClass": "iti__tm",
            //     "placeHolder": "+993 12 34-56-78"
            // },
            // {
            //     "name": "Turks and Caicos Islands",
            //     "iso2": "tc",
            //     "dialCode": "1649",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tc",
            //     "flagClass": "iti__tc",
            //     "placeHolder": "+1 649-712-1234"
            // },
            // {
            //     "name": "Tuvalu",
            //     "iso2": "tv",
            //     "dialCode": "688",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-tv",
            //     "flagClass": "iti__tv",
            //     "placeHolder": "+688 20 123"
            // },
            // {
            //     "name": "U.S. Virgin Islands",
            //     "iso2": "vi",
            //     "dialCode": "1",
            //     "priority": 1,
            //     "areaCodes": [
            //         "340"
            //     ],
            //     "htmlId": "iti-0__item-vi",
            //     "flagClass": "iti__vi",
            //     "placeHolder": "+1 340-642-1234"
            // },
            // {
            //     "name": "Uganda",
            //     "iso2": "ug",
            //     "dialCode": "256",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ug",
            //     "flagClass": "iti__ug",
            //     "placeHolder": "+256 31 2345678"
            // },
            {
                "name": "Ukraine (Україна)",
                "iso2": "ua",
                "dialCode": "380",
                "priority": 0,
                "htmlId": "iti-0__item-ua",
                "flagClass": "iti__ua",
                "placeHolder": "+380 3112 34567"
            },
            {
                "name": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
                "iso2": "ae",
                "dialCode": "971",
                "priority": 0,
                "htmlId": "iti-0__item-ae",
                "flagClass": "iti__ae",
                "placeHolder": "+971 2 234 5678"
            },
            {
                "name": "United Kingdom",
                "iso2": "gb",
                "dialCode": "44",
                "priority": 0,
                "htmlId": "iti-0__item-gb",
                "flagClass": "iti__gb",
                "placeHolder": "+44 121 234 5678"
            },
            {
                "name": "United States",
                "iso2": "us",
                "dialCode": "1",
                "priority": 0,
                "htmlId": "iti-0__item-us",
                "flagClass": "iti__us",
                "placeHolder": "+1 201-555-0123"
            },
            // {
            //     "name": "Uruguay",
            //     "iso2": "uy",
            //     "dialCode": "598",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-uy",
            //     "flagClass": "iti__uy",
            //     "placeHolder": "+598 2123 1234"
            // },
            // {
            //     "name": "Uzbekistan (Oʻzbekiston)",
            //     "iso2": "uz",
            //     "dialCode": "998",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-uz",
            //     "flagClass": "iti__uz",
            //     "placeHolder": "+998 66 905 01 23"
            // },
            // {
            //     "name": "Vanuatu",
            //     "iso2": "vu",
            //     "dialCode": "678",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-vu",
            //     "flagClass": "iti__vu",
            //     "placeHolder": "+678 22123"
            // },
            // {
            //     "name": "Vatican City (Città del Vaticano)",
            //     "iso2": "va",
            //     "dialCode": "39",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-va",
            //     "flagClass": "iti__va",
            //     "placeHolder": "+39 06 6981 2345"
            // },
            // {
            //     "name": "Venezuela",
            //     "iso2": "ve",
            //     "dialCode": "58",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ve",
            //     "flagClass": "iti__ve",
            //     "placeHolder": "+58 212-1234567"
            // },
            // {
            //     "name": "Vietnam (Việt Nam)",
            //     "iso2": "vn",
            //     "dialCode": "84",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-vn",
            //     "flagClass": "iti__vn",
            //     "placeHolder": "+84 210 1234 567"
            // },
            // {
            //     "name": "Wallis and Futuna",
            //     "iso2": "wf",
            //     "dialCode": "681",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-wf",
            //     "flagClass": "iti__wf",
            //     "placeHolder": "+681 72 12 34"
            // },
            // {
            //     "name": "Western Sahara (‫الصحراء الغربية‬‎)",
            //     "iso2": "eh",
            //     "dialCode": "212",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-eh",
            //     "flagClass": "iti__eh",
            //     "placeHolder": "+212 5288-12345"
            // },
            // {
            //     "name": "Yemen (‫اليمن‬‎)",
            //     "iso2": "ye",
            //     "dialCode": "967",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-ye",
            //     "flagClass": "iti__ye",
            //     "placeHolder": "+967 1 234 567"
            // },
            // {
            //     "name": "Zambia",
            //     "iso2": "zm",
            //     "dialCode": "260",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-zm",
            //     "flagClass": "iti__zm",
            //     "placeHolder": "+260 211 234 567"
            // },
            // {
            //     "name": "Zimbabwe",
            //     "iso2": "zw",
            //     "dialCode": "263",
            //     "priority": 0,
            //     "htmlId": "iti-0__item-zw",
            //     "flagClass": "iti__zw",
            //     "placeHolder": "+263 13 12345"
            // },
            // {
            //     "name": "Åland Islands",
            //     "iso2": "ax",
            //     "dialCode": "358",
            //     "priority": 1,
            //     "htmlId": "iti-0__item-ax",
            //     "flagClass": "iti__ax",
            //     "placeHolder": "+358 18 1234567"
            // }
        ]


        currency_list = [
            {"name":"Afghan Afghani","code":"AFA"},
            {"name":"Albanian Lek","code":"ALL"},
            {"name":"Algerian Dinar","code":"DZD"},
            {"name":"Angolan Kwanza","code":"AOA"},
            {"name":"Argentine Peso","code":"ARS"},
            {"name":"Armenian Dram","code":"AMD"},
            {"name":"Aruban Florin","code":"AWG"},
            {"name":"Australian Dollar","code":"AUD"},
            {"name":"Azerbaijani Manat","code":"AZN"},
            {"name":"Bahamian Dollar","code":"BSD"},
            {"name":"Bahraini Dinar","code":"BHD"},
            {"name":"Bangladeshi Taka","code":"BDT"},
            {"name":"Barbadian Dollar","code":"BBD"},
            {"name":"Belarusian Ruble","code":"BYR"},
            {"name":"Belgian Franc","code":"BEF"},
            {"name":"Belize Dollar","code":"BZD"},
            {"name":"Bermudan Dollar","code":"BMD"},
            {"name":"Bhutanese Ngultrum","code":"BTN"},
            {"name":"Bitcoin","code":"BTC"},
            {"name":"Bolivian Boliviano","code":"BOB"},
            {"name":"Bosnia-Herzegovina Convertible Mark","code":"BAM"},
            {"name":"Botswanan Pula","code":"BWP"},
            {"name":"Brazilian Real","code":"BRL"},
            {"name":"British Pound Sterling","code":"GBP"},
            {"name":"Brunei Dollar","code":"BND"},
            {"name":"Bulgarian Lev","code":"BGN"},
            {"name":"Burundian Franc","code":"BIF"},
            {"name":"Cambodian Riel","code":"KHR"},
            {"name":"Canadian Dollar","code":"CAD"},
            {"name":"Cape Verdean Escudo","code":"CVE"},
            {"name":"Cayman Islands Dollar","code":"KYD"},
            {"name":"CFA Franc BCEAO","code":"XOF"},
            {"name":"CFA Franc BEAC","code":"XAF"},
            {"name":"CFP Franc","code":"XPF"},
            {"name":"Chilean Peso","code":"CLP"},
            {"name":"Chinese Yuan","code":"CNY"},
            {"name":"Colombian Peso","code":"COP"},
            {"name":"Comorian Franc","code":"KMF"},
            {"name":"Congolese Franc","code":"CDF"},
            {"name":"Costa Rican ColÃ³n","code":"CRC"},
            {"name":"Croatian Kuna","code":"HRK"},
            {"name":"Cuban Convertible Peso","code":"CUC"},
            {"name":"Czech Republic Koruna","code":"CZK"},
            {"name":"Danish Krone","code":"DKK"},
            {"name":"Djiboutian Franc","code":"DJF"},
            {"name":"Dominican Peso","code":"DOP"},
            {"name":"East Caribbean Dollar","code":"XCD"},
            {"name":"Egyptian Pound","code":"EGP"},
            {"name":"Eritrean Nakfa","code":"ERN"},
            {"name":"Estonian Kroon","code":"EEK"},
            {"name":"Ethiopian Birr","code":"ETB"},
            {"name":"Euro","code":"EUR"},
            {"name":"Falkland Islands Pound","code":"FKP"},
            {"name":"Fijian Dollar","code":"FJD"},
            {"name":"Gambian Dalasi","code":"GMD"},
            {"name":"Georgian Lari","code":"GEL"},
            {"name":"German Mark","code":"DEM"},
            {"name":"Ghanaian Cedi","code":"GHS"},
            {"name":"Gibraltar Pound","code":"GIP"},
            {"name":"Greek Drachma","code":"GRD"},
            {"name":"Guatemalan Quetzal","code":"GTQ"},
            {"name":"Guinean Franc","code":"GNF"},
            {"name":"Guyanaese Dollar","code":"GYD"},
            {"name":"Haitian Gourde","code":"HTG"},
            {"name":"Honduran Lempira","code":"HNL"},
            {"name":"Hong Kong Dollar","code":"HKD"},
            {"name":"Hungarian Forint","code":"HUF"},
            {"name":"Icelandic KrÃ³na","code":"ISK"},
            {"name":"Indian Rupee","code":"INR"},
            {"name":"Indonesian Rupiah","code":"IDR"},
            {"name":"Iranian Rial","code":"IRR"},
            {"name":"Iraqi Dinar","code":"IQD"},
            {"name":"Israeli New Sheqel","code":"ILS"},
            {"name":"Italian Lira","code":"ITL"},
            {"name":"Jamaican Dollar","code":"JMD"},
            {"name":"Japanese Yen","code":"JPY"},
            {"name":"Jordanian Dinar","code":"JOD"},
            {"name":"Kazakhstani Tenge","code":"KZT"},
            {"name":"Kenyan Shilling","code":"KES"},
            {"name":"Kuwaiti Dinar","code":"KWD"},
            {"name":"Kyrgystani Som","code":"KGS"},
            {"name":"Laotian Kip","code":"LAK"},
            {"name":"Latvian Lats","code":"LVL"},
            {"name":"Lebanese Pound","code":"LBP"},
            {"name":"Lesotho Loti","code":"LSL"},
            {"name":"Liberian Dollar","code":"LRD"},
            {"name":"Libyan Dinar","code":"LYD"},
            {"name":"Lithuanian Litas","code":"LTL"},
            {"name":"Macanese Pataca","code":"MOP"},
            {"name":"Macedonian Denar","code":"MKD"},
            {"name":"Malagasy Ariary","code":"MGA"},
            {"name":"Malawian Kwacha","code":"MWK"},
            {"name":"Malaysian Ringgit","code":"MYR"},
            {"name":"Maldivian Rufiyaa","code":"MVR"},
            {"name":"Mauritanian Ouguiya","code":"MRO"},
            {"name":"Mauritian Rupee","code":"MUR"},
            {"name":"Mexican Peso","code":"MXN"},
            {"name":"Moldovan Leu","code":"MDL"},
            {"name":"Mongolian Tugrik","code":"MNT"},
            {"name":"Moroccan Dirham","code":"MAD"},
            {"name":"Mozambican Metical","code":"MZM"},
            {"name":"Myanmar Kyat","code":"MMK"},
            {"name":"Namibian Dollar","code":"NAD"},
            {"name":"Nepalese Rupee","code":"NPR"},
            {"name":"Netherlands Antillean Guilder","code":"ANG"},
            {"name":"New Taiwan Dollar","code":"TWD"},
            {"name":"New Zealand Dollar","code":"NZD"},
            {"name":"Nicaraguan CÃ³rdoba","code":"NIO"},
            {"name":"Nigerian Naira","code":"NGN"},
            {"name":"North Korean Won","code":"KPW"},
            {"name":"Norwegian Krone","code":"NOK"},
            {"name":"Omani Rial","code":"OMR"},
            {"name":"Pakistani Rupee","code":"PKR"},
            {"name":"Panamanian Balboa","code":"PAB"},
            {"name":"Papua New Guinean Kina","code":"PGK"},
            {"name":"Paraguayan Guarani","code":"PYG"},
            {"name":"Peruvian Nuevo Sol","code":"PEN"},
            {"name":"Philippine Peso","code":"PHP"},
            {"name":"Polish Zloty","code":"PLN"},
            {"name":"Qatari Rial","code":"QAR"},
            {"name":"Romanian Leu","code":"RON"},
            {"name":"Russian Ruble","code":"RUB"},
            {"name":"Rwandan Franc","code":"RWF"},
            {"name":"Salvadoran ColÃ³n","code":"SVC"},
            {"name":"Samoan Tala","code":"WST"},
            {"name":"Saudi Riyal","code":"SAR"},
            {"name":"Serbian Dinar","code":"RSD"},
            {"name":"Seychellois Rupee","code":"SCR"},
            {"name":"Sierra Leonean Leone","code":"SLL"},
            {"name":"Singapore Dollar","code":"SGD"},
            {"name":"Slovak Koruna","code":"SKK"},
            {"name":"Solomon Islands Dollar","code":"SBD"},
            {"name":"Somali Shilling","code":"SOS"},
            {"name":"South African Rand","code":"ZAR"},
            {"name":"South Korean Won","code":"KRW"},
            {"name":"Special Drawing Rights","code":"XDR"},
            {"name":"Sri Lankan Rupee","code":"LKR"},
            {"name":"St. Helena Pound","code":"SHP"},
            {"name":"Sudanese Pound","code":"SDG"},
            {"name":"Surinamese Dollar","code":"SRD"},
            {"name":"Swazi Lilangeni","code":"SZL"},
            {"name":"Swedish Krona","code":"SEK"},
            {"name":"Swiss Franc","code":"CHF"},
            {"name":"Syrian Pound","code":"SYP"},
            {"name":"São Tomé and Príncipe Dobra","code":"STD"},
            {"name":"Tajikistani Somoni","code":"TJS"},
            {"name":"Tanzanian Shilling","code":"TZS"},
            {"name":"Thai Baht","code":"THB"},
            {"name":"Tongan Pa'anga","code":"TOP"},
            {"name":"Trinidad & Tobago Dollar","code":"TTD"},
            {"name":"Tunisian Dinar","code":"TND"},
            {"name":"Turkish Lira","code":"TRY"},
            {"name":"Turkmenistani Manat","code":"TMT"},
            {"name":"Ugandan Shilling","code":"UGX"},
            {"name":"Ukrainian Hryvnia","code":"UAH"},
            {"name":"United Arab Emirates Dirham","code":"AED"},
            {"name":"Uruguayan Peso","code":"UYU"},
            {"name":"US Dollar","code":"USD"},
            {"name":"Uzbekistan Som","code":"UZS"},
            {"name":"Vanuatu Vatu","code":"VUV"},
            {"name":"Venezuelan BolÃvar","code":"VEF"},
            {"name":"Vietnamese Dong","code":"VND"},
            {"name":"Yemeni Rial","code":"YER"},
            {"name":"Zambian Kwacha","code":"ZMK"}
          ];
}
