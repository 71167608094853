import { Component, NgZone, OnInit, ChangeDetectorRef } from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Platform } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { GlobalService } from './services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
// import { Deploy } from 'cordova-plugin-ionic';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoadingController } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FingerprintAIO,FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { StatusBar } from '@capacitor/status-bar';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  firebasePlugin;

  showFingerPrint() {
 
    const options: FingerprintOptions = {
        title:'Scan',
        subtitle:'Use Face ID',
        disableBackup:true,
        cancelButtonTitle:'Login'
      };
      this.faio.isAvailable().then((isAvailable) => {
        console.log('isAvailable', isAvailable);
        if (isAvailable === "finger" || isAvailable === "face") {
          this.faio
            .show(options)
            .then((result: any) => {
              this.loginFromSession()
console.log(result)
              console.log('Inside success');

            })
            .catch(e => {
              this.global.logout()
              console.log(e)
              console.log('Inside failure on cancel');
            });
        }
      }, error => {
        this.loginFromSession()
        console.log('isAvaialble error', error);
      });
 

  }
  constructor(
    private faio: FingerprintAIO,
    private ref: ChangeDetectorRef,
    public ngzone: NgZone,
    private activatedRoute: ActivatedRoute,
    public api: ApiService,
    private loadingCtrl: LoadingController,
    public global: GlobalService,
    public router: Router,
    private platform: Platform,
    public deploy: Deploy,
    private screenOrientation: ScreenOrientation,
    public firebaseX: FirebaseX
  ) {
    this.global.enterType = 'Register'

    try{
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT).catch(err=>console.log(err));
    }catch(err){

    }
   
    // this.changeToBetaChannel()
    this.initializeApp()
    if (localStorage.getItem('monitor')) {
      this.global.monitorCode = localStorage.getItem('monitor')
      this.global.MONITOR_ON = true
      let awsmobile = {
        "aws_project_region": "eu-central-1",
        "aws_cognito_identity_pool_id": "eu-central-1:38fbb94a-9560-44fc-8378-c8f8145e5379",
        "aws_cognito_region": "eu-central-1",
        "aws_user_pools_id": "eu-central-1_B2crRT2IB",
        "aws_user_pools_web_client_id": "5er8rbau7kcljeeppks30bphfj",
        Auth: {
          mandatorySignIn: false
        }
        // "aws_mandatory_sign_in": 'enable'
      };
      Amplify.configure(awsmobile);

    }


  }
  async changeToBetaChannel() {
    await this.deploy.configure({ channel: 'appstore' });
  }
  async showLoading() {
    const loading = await this.loadingCtrl.create({
      message: `Dismissing after ${this.Percent} seconds...`,
      // duration: 3000,
    });

    loading.present();
  }
  Percent = 0
  async performManualUpdate() {
    const update = await this.deploy.checkForUpdate()
    if (update.available) {
      this.global.goToPage('auth/splash-screen')
    } else {
      console.log('no updates')
    }
  }

  async initializeApp() {
    try{
      StatusBar.hide().catch(err=>console.log(err))
      await SplashScreen.show({
        autoHide: true
      }).catch(err=>console.log(err))
    }catch(err){

    }
  

    // this.splashScreen.show()
    this.platform.ready().then(() => {

     
      // console.log('is platform andorid - ',this.platform.is('android'))
      // console.log('is platform ios - ',this.platform.is('ios'))
      // this.firebasePlugin = (<any>window).FirebasePlugin;
      // if (this.platform.is('cordova')) {
        // if (this.platform.is('android')) {
        //   this.initializeFireBaseAndroid();
        // }
        // if (this.platform.is('ios')) {
          // this.initializeFireBaseIos();
        // }
      // }


      this.performManualUpdate()


    });
  }


  private initializeFireBaseAndroid(): Promise<any> {

    return 
    return this.firebasePlugin.getToken(token => {
      this.firebasePlugin.getToken(token => {
        this.update_push_token_server(token)
        this.firebasePlugin.subscribe("all");
        this.subscribeToPushNotificationEvents();
      });
    });
  }

   initializeFireBaseIos(): Promise<any> {


    return
    return this.firebasePlugin.grantPermission(data => {
      this.firebasePlugin.getToken(token => {
        this.update_push_token_server(token)
        console.log('push eden - ',token)
        this.firebasePlugin.subscribe("all");
        this.subscribeToPushNotificationEvents();
      });
    });
  }



  subscribeToPushNotificationEvents() {
    // Handle token refresh

    this.firebasePlugin.onTokenRefresh((token) => {
      this.update_push_token_server(token)
      console.log('push eden - ',token)
    });


    this.firebasePlugin.onMessageReceived((notification) => {
      if (!notification.tap) {
        console.log(notification)
        //alert(JSON.stringify(notification));
      }
      else {

      }
    })
  }
  update_push_token_server(token){
    let obj:any = {
      push_token:token
    }
    this.api.sendRequest('update_push_token_server',obj).then(data=>{

    }).catch(err=>{
      
    })
  }



  returnSession = 0
  async ngOnInit() {

    // setTimeout(() => {
      this.firebasePlugin = (<any>window).FirebasePlugin;
      // this.firebasePlugin.getToken(token => {
      //   console.log('push eden - ',token)
        // this.firebasePlugin.subscribe("all");
        // this.subscribeToPushNotificationEvents();
      // });
      // this.initializeFireBaseIos()
      // const w: any = window;
      // try{
      //   this.firebasePlugin.requestPushPermissionIOS(() => {
      //     console.log('push permissions success');
      //   }, (e) => {
      //     console.log('push permissions fail', e);
      //   });
      // }catch(err){
      //   console.log('eden - ', err)
      // }



    //   this.firebaseX.getToken()
    //   .then(token => console.log(`The token is ${token}`)) // save the token server-side and use it to push notifications to this device
    //   .catch(error => console.error('Error getting token', error));
    
    // this.firebaseX.onMessageReceived()
    //   .subscribe(data => console.log(`User opened a notification ${data}`));
    
    // this.firebaseX.onTokenRefresh()
    //   .subscribe((token: string) => console.log(`Got a new token ${token}`));
  
    // }, 10000);
   
 

    await Auth.currentSession().then(user => {
      this.global.returnSession = 1

    })
      .catch((err: any) => {
        this.global.returnSession = 0
      //  this.global.logout()
        // this.global.logout()
       
        console.log('eden error -',err)
        // console.log(err)
      }


      );
      this.initiateDetectChanges()
      
  }

initiateDetectChanges(){
  onAuthUIStateChange((nextAuthState, authData) => {
    this.global.authState = nextAuthState;
    console.log('nextAuthState - ',nextAuthState)
    console.log('authData - ',authData)
    this.global.cognito_user = authData as CognitoUserInterface;
    if (nextAuthState === AuthState.SignedIn || nextAuthState === AuthState.VerifyContact) {

      this.global.MONITOR_CONNECTED = true
      if (!this.global.MONITOR_ON) {

        if (this.global.loginType == 'Terminal') {
          this.login(1)
          // this.global.goToPage('auth/home')
        } else {
          this.login()
        
          
        }
      }
    }

    if (nextAuthState === AuthState.SignOut) {
      this.global.logout()
    }
    if (!authData) {
      // this.global.goToPage('auth/welcome-modal')
      this.global.goToPage('/login')

    }
    this.ngzone.run(() => {
      this.ref.detectChanges()
    });
  })
}
  async login(a = null) {

    try{
      if(localStorage.getItem('face_id_enable')){
        if(localStorage.getItem('face_id_enable') == 'false'){
          this.global.face_id_enable = false
        }else{
          this.global.face_id_enable = true
        }
        // console.log(localStorage.getItem('face_id_enable'))
      }
      // if(this.global.face_id_enable == true){
  
      // }
      if(this.global.returnSession == 1 && this.global.face_id_enable == true){
        this.showFingerPrint()
        return
      }
    }catch(err){

    }
   
    // alert('3')
    this.global.presentLoading()
    await this.retreiveUserAttributes()
    this.global.niceLoader = true
    let url = 'bitandpay_login';
    this.api.sendRequest(url, {})
      .then((data: any) => {
        this.global.returnSession = 0
        this.global.dismissLoading()
        this.retreiveUserAttributes()
        console.log(data)
        this.global.niceLoader = false
        if (data == '0') {
          this.global.logout()
          // this.login_wrong_details_error = true;
        } else {
          this.global.complianceFileds = data.white_list

          // this.global.nav_('/controller')
          try {
            if (data.wallet == '1') {
              this.global.complianceDone = true
            }
          } catch (err) {

          }
          this.global.auth_variable_pages = 'app';
          this.global.user = data;
          localStorage.setItem('crypto_admin_user', JSON.stringify(data));
          localStorage.setItem('crypto_admin_user_token', data.token);
          if (a == 1) {
            this.global.goToPage('auth/home')
          }
          if (this.platform.is('android')) {
            this.initializeFireBaseAndroid();
          }
          if (this.platform.is('ios')) {
            this.initializeFireBaseIos();
          }
          // this.initializeFireBaseIos()

          console.log(this.global.user.user_type)
          if(!this.global.complianceDone){
            if(this.global.user.user_type == 'Merchant'){
              this.global.goToPage('auth/business-details')
            }else{
              this.global.goToPage('auth/personal-details')
            }
            
            // this.presentModal()
            // return
          }else{
            if(this.global.user.user_type == 'Merchant'){
              this.global.goToPage('auth/settings')
            }else{
              this.global.goToPage('auth/credit-card-list')
            }
            
          }
        }
        // this.global.dismissLoading()
      }).catch(err => {
        this.global.dismissLoading()
        console.log(err)
        this.global.niceLoader = false
      })
  }
  async retreiveUserAttributes() {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        console.log(user)
        // this.authUserAttributes()

      })
        .catch((err: any) => {
          this.global.logout()
          console.log(err)
        }


        );
    } catch (error) {
      console.log(error)
    }
  }


  async loginFromSession(){
    // alert('2')
    this.global.presentLoading()
    await this.retreiveUserAttributes()
    this.global.niceLoader = true
    let url = 'bitandpay_login';
    this.api.sendRequest(url, {})
      .then((data: any) => {
        this.global.dismissLoading()
        this.retreiveUserAttributes()
        console.log(data)
        this.global.niceLoader = false
        if (data == '0') {
          this.global.logout()
          // this.login_wrong_details_error = true;
        } else {
          this.global.complianceFileds = data.white_list

          // this.global.nav_('/controller')
          try {
            if (data.wallet == '1') {
              this.global.complianceDone = true
            }
          } catch (err) {

          }
          this.global.auth_variable_pages = 'app';
          this.global.user = data;
          localStorage.setItem('crypto_admin_user', JSON.stringify(data));
          localStorage.setItem('crypto_admin_user_token', data.token);
          if (this.platform.is('android')) {
            this.initializeFireBaseAndroid();
          }
          if (this.platform.is('ios')) {
            this.initializeFireBaseIos();
          }
          // this.initializeFireBaseIos()
          if(!this.global.complianceDone){
            if(this.global.user.user_type == 'Merchant'){
              this.global.goToPage('auth/business-details')
            }else{
              this.global.goToPage('auth/personal-details')
            }
            
            // this.presentModal()
            // return
          }else{
            if(this.global.user.user_type == 'Merchant'){
              this.global.goToPage('auth/settings')
            }else{
              this.global.goToPage('auth/credit-card-list')
            }
            
          }
        }
        // this.global.dismissLoading()
      }).catch(err => {
        this.global.dismissLoading()
        console.log(err)
        this.global.niceLoader = false
      })
  }
}
