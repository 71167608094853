import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http'
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { GlobalService } from './services/global.service';
import { ApiService } from './services/api.service';
import { AuthService } from "./services/auth.service";
import { TabsComponent } from './shared/tabs/tabs.component';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);


@NgModule({
  declarations: [AppComponent, TabsComponent],
  imports: [
    BrowserAnimationsModule,
    AmplifyUIAngularModule,
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [FingerprintAIO, FirebaseX, ScreenOrientation, Deploy, AuthService, ApiService, GlobalService, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  exports: [AmplifyUIAngularModule]
})
export class AppModule { }
